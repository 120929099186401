import { post } from "./utils";
import { NotificationFormType, NotificationType } from "utils/types";
import { ResponseStatus } from "utils/enums";

type NotificationListResponse = {
  success: boolean;
  data: NotificationType[];
  currentPage: number;
  totalCount: number;
  countPerPage: number;
  status: ResponseStatus;
};

type CreateResponse = {
  success: string;
  data: NotificationType;
  status: ResponseStatus;
  msg?: string;
};

export const createOrUpdateNotification = async (
  payload: NotificationFormType
) => {
  const response = await post<CreateResponse>({
    route: "notification/createOrUpdate",
    payload,
  });

  return response || null;
};

export const listNotifications = async ({
  dealId,
  page,
  includeOwner,
}: {
  dealId: string;
  page: number;
  includeOwner?: boolean;
}) => {
  const notifications = await post<NotificationListResponse>({
    route: "notification/list",
    payload: {
      dealId,
      page,
      includeOwner,
    },
  });

  return notifications;
};
