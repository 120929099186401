import { useCallback, useEffect, useState } from "react";
import { DealType, UserType } from "utils/types";
import {
  LegalEntityType,
  LegalEntityTypeTitle,
  ProceduresTitles,
} from "utils/enums";
import { Procedure, ProcedureToDealstage } from "utils/enums";
import { useRecoilValue } from "recoil";
import { useIsMounted } from "hooks/useIsMounted";
import userRoute from "atoms/userRoute";
import { listUsersForCompany } from "requests/user";
import { getDealManager } from "requests/deal";
import InputView from "components/shared/InputView";

const DealDataView = ({ deal }: { deal: DealType }) => {
  const isMounted = useIsMounted();
  const [users, setUsers] = useState<UserType[]>([]);
  const [manager, setManager] = useState<UserType | null>(null);
  const usrRoute = useRecoilValue(userRoute);

  const getUsers = useCallback(async () => {
    if (deal.company?.id) {
      const usrs = await listUsersForCompany(
        usrRoute.request,
        deal.company?.id
      );
      setUsers(usrs);
    }
  }, [deal.company?.id, usrRoute.request]);

  const getManager = useCallback(async () => {
    if (deal.id) {
      const mng = await getDealManager(deal.id);
      setManager(mng);
    }
  }, [deal.id]);

  useEffect(() => {
    if (isMounted()) {
      getUsers();
      getManager();
    }
  }, [getUsers, getManager, isMounted]);

  const user = users.find((u) => u.id === deal.owner?.id);

  return (
    <div className="space-y-12 mt-4">
      <h2>Información General</h2>

      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 gap-y-10">
        <InputView
          label="Persona"
          value={
            deal.debtorEntityType !== undefined
              ? LegalEntityTypeTitle[LegalEntityType[deal.debtorEntityType]]
              : ""
          }
        />
        <InputView label="Empresa Contraparte" value={deal.debtor || ""} />
        <InputView
          label="Cotacto Contraparte"
          value={deal.debtorContact || ""}
        />
        <InputView label="Email Contraparte" value={deal.debtorEmail || ""} />

        <InputView label="Rut Contraparte" value={deal.debtorTaxId || ""} />
        <InputView
          label="Dirección Contraparte"
          value={deal.debtorAddress || ""}
        />
        <InputView label="URL" value={deal.externalUrl || ""} />
        <InputView
          label="Comisión Recupero (%)"
          value={deal.comission ? String(deal.comission) : "0"}
        />
      </div>
      <h2>Información Juicio</h2>
      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 gap-y-10">
        <InputView
          label="Procedimiento"
          value={
            deal.procedure !== undefined
              ? ProceduresTitles[Procedure[deal.procedure]]
              : ""
          }
        />

        <InputView
          label="Estado juicio"
          value={
            deal.procedure !== undefined && deal.dealstage !== undefined
              ? ProcedureToDealstage[Procedure[deal.procedure]][deal.dealstage]
              : ""
          }
        />

        <InputView label="Carátula" value={deal.dealname || ""} />

        <InputView label="Rol" value={deal.caseId || ""} />

        <InputView label="Tribunal" value={deal.court?.name || ""} />

        <InputView
          label="Monto demandado"
          value={String(deal.totalDebt || 0)}
        />

        <InputView label="En Rebeldía" value={deal.inDefault ? "Sí" : "No"} />

        <InputView
          label="Usuario Asignado"
          value={user ? `${user?.firstName} ${user?.lastName}` : "No asignado"}
        />
        <InputView
          label="Abogado Responsable"
          value={`${manager?.firstName} ${manager?.lastName}`}
        />
      </div>
    </div>
  );
};

export default DealDataView;
