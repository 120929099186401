const DealsHeaderClient = () => {
  return (
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-base font-semibold leading-6 text-gray-900">
          Tabla Juicios
        </h1>
      </div>
    </div>
  );
};

export default DealsHeaderClient;
