import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { uploadDocuments } from "requests/document";
import alert from "atoms/alert";
import { ResponseStatus } from "utils/enums";
import { Spinner } from "flowbite-react";
import { classNames, abbrString } from "utils/utils";
import {
  MAX_FILE_SIZE_MB,
  allowedFiles,
  allowedMimeType,
} from "utils/constants";

const DocumentsFooter = ({
  dealId,
  onUpload,
}: {
  dealId: string;
  onUpload: () => void;
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const setAlerta = useSetRecoilState(alert);
  const [uploading, setUploading] = useState(false);

  const onFilesUpload = async () => {
    if (files.length > 0) {
      setUploading(true);
      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      for (const file of files) {
        formData.append("files", file, file?.name);
      }

      formData.append("dealId", dealId);

      try {
        const res = await uploadDocuments(formData);
        if (res?.status === ResponseStatus.Ok) {
          setAlerta({
            display: true,
            variant: "success",
            message: "Documento cargado con éxito",
          });
        } else {
          setAlerta({
            display: false,
            variant: "error",
            message: "Hubo un error creando el documento",
          });
        }
        setUploading(false);
        onUpload();
      } catch (e) {
        setAlerta({
          display: false,
          variant: "error",
          message: "Hubo un error creando el documento",
        });
        setUploading(false);
      }
    }
  };

  return (
    <div className="flex flex-column items-end">
      <div className="sm:flex sm:justify-end mt-4 sm:mt-0 sm:flex-none gap-x-2">
        <div className="flex align-items-center">
          {files.length > 0 && (
            <span className="text-xs">
              {`Files: `}
              {files.map((f) => abbrString(f.name)).join(", ")}
            </span>
          )}
        </div>
        <FilesInput onSelect={setFiles} />

        <button
          type="button"
          className={classNames(
            "block rounded-md  px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ",
            Boolean(files.length > 0)
              ? "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
              : "bg-gray-600"
          )}
          disabled={!Boolean(files.length > 0)}
          onClick={onFilesUpload}
        >
          Subir Documento {uploading && <Spinner />}
        </button>
      </div>
      <span className="text-xs pt-3">Máximo 10 archivos de 2 MB</span>
    </div>
  );
};

export default DocumentsFooter;

const FilesInput = ({ onSelect }: { onSelect: (f: File[]) => void }) => {
  const setAlerta = useSetRecoilState(alert);

  const onFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files && e.target?.files?.length > 0) {
      const files = [] as File[];
      for (let i = 0; i < e.target.files.length; i++) {
        files.push(e.target.files[i]);
      }

      const types = files.map((f) => f.type);
      console.log("f", files);

      if (files.length > 10) {
        setAlerta({
          display: true,
          variant: "warning",
          message: "Máximo 10 documentos a la vez",
        });
        onSelect([]);
        return;
      }

      if (types.some((type) => !allowedMimeType.includes(type))) {
        setAlerta({
          display: true,
          variant: "warning",
          message: `Documento debe tener uno de los siguientes formatos: ${allowedFiles.join(
            ", "
          )}`,
        });
        onSelect([]);
        return;
      }
      const filesizes = files.map((f) =>
        Number(((f?.size || 0) / 1024 / 1024).toFixed(4))
      );

      if (filesizes.some((filesize) => filesize > MAX_FILE_SIZE_MB)) {
        setAlerta({
          display: true,
          variant: "warning",
          message: `Documento debe ser ${MAX_FILE_SIZE_MB}MB máximo`,
        });
        onSelect([]);
        return;
      }

      onSelect(files);
    }
  };

  return (
    <input
      onChange={onFilesChange}
      className="block text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
      id="file_input"
      type="file"
      multiple={true}
    />
  );
};
