import { post, get } from "./utils";
import { UserFormType, UserType } from "utils/types";
import { ResponseStatus } from "utils/enums";

type ListResponse = {
  success: boolean;
  data: UserType[];
  status: ResponseStatus;
};

export const listUsers = async () => {
  const user = await get<ListResponse>({
    route: "admin/user/list",
  });

  return user?.data || [];
};

export const listSiblingUsers = async () => {
  const user = await get<ListResponse>({
    route: "admin/user/list-sibling",
  });

  return user?.data || [];
};

export const listUsersForCompany = async (
  userRoute: string,
  companyId: string
) => {
  const user = await post<ListResponse>({
    route: `${userRoute}user/listForCompany`,
    payload: {
      companyId,
    },
  });

  return user?.data || [];
};

type CountResponse = {
  success: boolean;
  data: {
    [index: string]: { total: number; confirmed: number };
  };
  status: ResponseStatus;
};

export const getUserCountForCompanyIds = async (companyIds: string[]) => {
  const res = await post<CountResponse>({
    route: "admin/user/countForCompanyIds",
    payload: {
      companyIds,
    },
  });

  return res?.data || {};
};

type CreateResponse = {
  success: string;
  data: UserType;
  msg: string;
  status: ResponseStatus;
};

export const createOrUpdateUser = async (payload: UserFormType) => {
  const user = await post<CreateResponse>({
    route: "admin/user/createOrUpdate",
    payload,
  });

  return user || null;
};

type GetResponse = CreateResponse & {};
export const getUser = async (userId: string) => {
  const user = await post<GetResponse>({
    route: "admin/user/get",
    payload: {
      userId,
    },
  });

  return user?.data || null;
};

export const deleteUser = async (userId: string) => {
  const res = await post<{ success: boolean }>({
    route: "admin/user/delete",
    payload: {
      userId,
    },
  });

  return res?.success;
};
