import { ReactNode, useCallback, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import accountInState from "atoms/account";
import AdminNavbar from "components/admin/AdminNavbar";
import ClientNavbar from "components/client/ClientNavbar";
import Alert from "./shared/Alert";
import ConfirmationModal from "./shared/ConfirmationModal";
import { Roles } from "utils/enums";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuthUser } from "requests/auth";
import userRoute from "atoms/userRoute";
import Loading from "./Loading";

function MainLayot({ children }: { children: ReactNode }) {
  const [userAccount, setUserAccount] = useRecoilState(accountInState);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const setUserRoute = useSetRecoilState(userRoute);

  const getUser = useCallback(async () => {
    const user = await getAuthUser();

    if (user) {
      setUserAccount({
        userId: user.id,
        email: user.email,
        role: user.role,
        companyId: user.companyId || "",
      });

      if (user.role > Roles.internal) {
        setUserRoute({ request: "admin/", navigation: "admin" });
      } else {
        setUserRoute({ request: "", navigation: "acreedor" });
      }
    } else {
      if (search) {
        // If there is a search query, append it to the login route to redirect
        // back to the same page after login
        navigate(`/login${search}`);
        return;
      }
      navigate(`/login`);
    }
  }, [setUserAccount, setUserRoute]);

  useEffect(() => {
    setTimeout(getUser, 1000);
  }, []);

  if (!userAccount.userId) {
    return <Loading />;
  }

  if (
    (pathname === "/acreedor" && userAccount.role > Roles.client) ||
    (pathname === "/admin" && userAccount.role < Roles.internal)
  ) {
    return <div>No access</div>;
  }

  return (
    <div className="min-h-full">
      <Alert />
      <ConfirmationModal />
      {userAccount.role > Roles.client ? <AdminNavbar /> : <ClientNavbar />}
      {children}
    </div>
  );
}

export default MainLayot;
