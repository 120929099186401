import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserFormType } from "utils/types";
import { createOrUpdateUser, deleteUser, getUser } from "requests/user";
import { classNames, validateEmail } from "utils/utils";
import { useSetRecoilState } from "recoil";
import alert from "atoms/alert";
import confirmModal from "atoms/confirmModal";
import { RoleSp } from "utils/enums";

const initialState: UserFormType = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  companyRole: "",
  phone: "",
  role: 0,
};
const UserForm = ({
  companyId,
  userId,
}: {
  companyId: string;
  userId?: string;
}) => {
  const navigate = useNavigate();
  const setAlerta = useSetRecoilState(alert);
  const setConfirmModal = useSetRecoilState(confirmModal);
  const [payload, setPayload] = useState(initialState);
  const [confirmed, setConfirmed] = useState(false);

  const createdUserId = useMemo(() => userId, [userId]);

  useEffect(() => {
    const getUserData = async (uId: string) => {
      const u = await getUser(uId);

      setPayload({
        id: createdUserId,
        firstName: u?.firstName || "",
        lastName: u?.lastName || "",
        email: u?.email || "",
        role: u?.role || 0,
        companyRole: u?.companyRole || "",
        phone: u?.phone || "",
      });
      setConfirmed(u?.confirmed || false);
    };
    if (createdUserId) {
      getUserData(createdUserId);
    } else {
      setPayload(initialState);
    }
  }, [createdUserId]);
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const u = await createOrUpdateUser({
      ...payload,
      id: createdUserId,
      companyId,
    });

    if (u?.status === "ok") {
      setAlerta({
        display: true,
        variant: "success",
        message: "Usuario creado o actualizado con éxito",
      });
      navigate(-1);
    } else {
      setAlerta({
        display: true,
        variant: "error",
        message: u?.msg || "Hubo un error creando / actualizando el usuario",
      });
    }
  };

  const handleFieldChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const newPayload = {
        ...payload,
        [key]: e.currentTarget.value,
      };
      setPayload(newPayload);
    };

  const setDeleteModal = (userName: string) => {
    setConfirmModal({
      display: true,
      onConfirm: async () => {
        await handleDelete();
      },
      title: "Borrar usuario",
      variant: "alert",
      message: `¿Estas seguro(a) de querer borrar el usuario ${userName} ?`,
    });
  };

  const handleDelete = async () => {
    if (createdUserId) {
      const deleteSuccess = await deleteUser(createdUserId);
      if (deleteSuccess) {
        setAlerta({
          display: true,
          variant: "success",
          message: "Usuario borrado con éxito",
        });
        navigate(-1);
      } else {
        setAlerta({
          display: false,
          variant: "error",
          message: "Hubo un error al borrar el usuario",
        });
      }
    }
  };

  const formValid = useMemo(() => {
    const validEmail = validateEmail(payload.email);
    const noEmptyFields =
      payload.email && payload.firstName && payload.lastName;

    return noEmptyFields && validEmail;
  }, [payload]);

  // const handleSelectChange =
  //   (key: string) => (e: React.ChangeEvent<HTMLSelectElement>) => {
  //     e.preventDefault();
  //     const newPayload = {
  //       ...payload,
  //       [key]: e.currentTarget.value,
  //     };
  //     setPayload(newPayload);
  //   };
  // console.log("payload", payload);

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nombre
              </label>
              <div className="mt-2">
                <input
                  onChange={handleFieldChange("firstName")}
                  type="text"
                  name="first-name"
                  id="first-name"
                  value={payload.firstName}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Apellido
              </label>
              <div className="mt-2">
                <input
                  onChange={handleFieldChange("lastName")}
                  type="text"
                  name="last-name"
                  id="last-name"
                  value={payload.lastName}
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <div className="">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      onChange={handleFieldChange("email")}
                      type="text"
                      name="website"
                      id="website"
                      value={payload.email}
                      className="block flex-1 border-0 bg-transparent pl-3 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="your@email.com"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:col-span-3">
              <div className="">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Teléfono
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      onChange={handleFieldChange("phone")}
                      type="text"
                      name="phone"
                      id="phone"
                      value={payload.phone}
                      className="block flex-1 border-0 bg-transparent pl-3 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="+56 9232 1234"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:col-span-3">
              <div className="">
                <label
                  htmlFor="phoncompanyRolee"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Cargo
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      onChange={handleFieldChange("companyRole")}
                      type="text"
                      name="companyRole"
                      id="companyRole"
                      value={payload.companyRole}
                      className="block flex-1 border-0 bg-transparent pl-3 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="Ej. Abogado, Asistente, etc."
                    />
                  </div>
                </div>
              </div>
            </div>
            {payload.role ? (
              <div className="sm:col-span-3">
                <div className="">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Rol
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-100 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        // onChange={handleFieldChange("email")}
                        type="text"
                        name="role"
                        id="role"
                        disabled
                        value={RoleSp[payload.role]}
                        className="block flex-1 border-0 bg-transparent pl-3 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="sm:col-span-3">
              <div className="">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Confirmado
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-100 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    {
                      <input
                        // onChange={handleFieldChange("email")
                        name="role"
                        id="role"
                        disabled
                        value={confirmed ? "Si" : "No"}
                        className="block flex-1 border-0 bg-transparent pl-3 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-between">
        {createdUserId && (
          <button
            type="button"
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            onClick={() =>
              setDeleteModal(`${payload.firstName} ${payload.lastName}`)
            }
          >
            Borrar
          </button>
        )}
        <div className="flex gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => navigate(-1)}
          >
            Atrás
          </button>
          <button
            type="submit"
            disabled={!formValid}
            className={
              formValid
                ? classNames(
                    "rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  )
                : classNames(
                    "rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  )
            }
          >
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default UserForm;
