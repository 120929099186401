import { useNavigate } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/24/outline";

const UsersHeader = ({ companyId }: { companyId: string }) => {
  const navigate = useNavigate();

  return (
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-base font-semibold leading-6 text-gray-900">
          Tabla Usuarios
        </h1>
      </div>
      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <button
          type="button"
          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => {
            navigate(`?companyId=${companyId}&clientState=users&userId=new`);
          }}
        >
          <PlusIcon className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
};

export default UsersHeader;
