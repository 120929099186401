import { default as React, useEffect, useRef } from "react";
import EditorJS, { EditorConfig, OutputData } from "@editorjs/editorjs";
import Header from "@editorjs/header";
import AttachesTool from "@editorjs/attaches";

const EditorRead = ({ data, id }: { id: string; data: OutputData }) => {
  const ejInstance = useRef<EditorJS | null>();

  // Only first time
  useEffect(() => {
    if (!ejInstance.current) {
      initEditor();
    }
    return () => {
      ejInstance?.current?.destroy();
      ejInstance.current = null;
    };
  }, []);

  const initEditor = () => {
    let tools: EditorConfig["tools"] = {
      header: Header,
      attaches: AttachesTool,
    };

    const editor = new EditorJS({
      holder: `editorjs-read-only-${id}`,
      data,
      onReady: () => {
        ejInstance.current = editor;
      },
      readOnly: true, // Issue with ReadOnly style
      tools,
      minHeight: 10,
    });
  };

  return (
    <React.Fragment>
      <div
        className="editor-read-only border border-blue-50 rounded-md p-2 mt-2 disable-tailwind-font-size bg-gray-100"
        id={`editorjs-read-only-${id}`}
      >
        {" "}
      </div>
    </React.Fragment>
  );
};

export default EditorRead;
