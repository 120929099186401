import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { get } from "requests/utils";
import { ResponseStatus } from "utils/enums";

type DocumentGetResponse = {
  url: string;
  status: ResponseStatus;
};

const FileDownloader = () => {
  const [message, setMessage] = useState("Downloading file...");
  const location = useLocation();

  const urlParams = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );
  const { documentId } = useMemo(
    () => ({
      documentId: urlParams.get("documentId") || undefined,
    }),
    [urlParams]
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (documentId) {
          const response = await get<DocumentGetResponse>({
            route: `document/getByQuery?documentId=${documentId}`,
          });

          if (response?.url) {
            window.location.href = response.url; // Assuming the URL is in the 'url' property of the response data
          } else {
            throw new Error("No URL found in response data");
          }
        }
      } catch (error) {
        setMessage(
          "Error al descargar el documento. Certifíquese de que el documento no haya sido eliminado."
        );
        console.error("Error fetching URL:", error);
      }
    };

    fetchData();
  }, [documentId]);

  return <div>{message}</div>;
};

export default FileDownloader;
