import { useCallback, useEffect, useState } from "react";
import { listCompanies } from "requests/company";
import { getUserCountForCompanyIds } from "requests/user";
import { CompanyType } from "utils/types";
import { useNavigate } from "react-router-dom";
import { useIsMounted } from "hooks/useIsMounted";
import userRoute from "atoms/userRoute";
import { useRecoilValue } from "recoil";

export default function CompaniesTable() {
  const [companies, setCompanies] = useState<CompanyType[]>([]);
  const [userCount, setUserCount] = useState<
    | {
        [index: string]: { total: number; confirmed: number };
      }
    | undefined
  >(undefined);
  const navigate = useNavigate();
  const usrRoute = useRecoilValue(userRoute);

  const isMounted = useIsMounted();
  useEffect(() => {
    const getData = async () => {
      const cmps = await listCompanies();
      setCompanies(cmps);
    };
    if (isMounted()) {
      getData();
    }
  }, []);

  const onGetUserCount = useCallback(async () => {
    const cs = companies.map((c) => c.id);
    const c = await getUserCountForCompanyIds(cs);
    setUserCount(c);
  }, [companies]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                  >
                    Nombre
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Rut
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Ciudad
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    <button
                      onClick={onGetUserCount}
                      type="button"
                      className="relative px-2 py-1 ml-auto flex-shrink-0 rounded-full bg-indigo-600 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                    >
                      Usuarios Confirmados
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {companies.length ? (
                  companies.map((company) => (
                    <tr key={company.name}>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        <div
                          onClick={() => {
                            navigate(
                              `/${usrRoute.navigation}?companyId=${company.id}`
                            );
                          }}
                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                        >
                          <span>{company.name}</span>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {company.taxId}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {company.city}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {userCount
                          ? userCount[company.id]
                            ? `${userCount[company.id]?.confirmed} / ${
                                userCount[company.id]?.total
                              } confirmados`
                            : "0 / 0"
                          : "-"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <h6 className="pt-8">Tabla Vacía</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
