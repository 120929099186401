import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/20/solid";
import { NotificationParsedType } from "utils/types";
import { dateToLocalDateTime } from "utils/utils";
import EditorRead from "../EditorRead";
import { OutputData } from "@editorjs/editorjs";

export default function NotificationList({
  nots,
  empty,
}: {
  nots: NotificationParsedType[];
  empty: boolean;
}) {
  return (
    <div className="flow-root overscroll-y-auto pt-8">
      <ul className="-mb-8 h-500">
        {nots.map((notification, notificationIdx) => (
          <li key={notification.id}>
            <div className="relative pb-3">
              {notificationIdx !== nots.length - 1 ? (
                <span
                  className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex items-start space-x-3">
                <>
                  <div className="relative">
                    <img
                      className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                      src={notification.owner?.imageUrl}
                      alt=""
                    />

                    <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                      <ChatBubbleLeftEllipsisIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1">
                    <div>
                      <div className="text-xs">
                        {notification.owner ? (
                          <a href="/" className="font-medium text-gray-900">
                            {`${notification.owner.firstName} ${notification.owner.lastName}`}
                          </a>
                        ) : (
                          <span>Usuario borrado</span>
                        )}
                      </div>
                      <p className="mt-0.5 text-xs text-gray-500">
                        Escribió el{" "}
                        {dateToLocalDateTime(notification.updatedAt)}
                      </p>
                    </div>
                    <div className="mt-2 text-gray-700">
                      <EditorRead
                        id={notification.id}
                        data={notification.content as OutputData}
                      />
                    </div>
                  </div>
                </>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="flex flex-row justify-start pl-10 pt-4">
        {empty ? <span>No hay (más) mensajes</span> : null}
      </div>
    </div>
  );
}
