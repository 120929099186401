import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import alert from "atoms/alert";
import userRoute from "atoms/userRoute";
import { getDeal, createOrUpdateDealNotification } from "requests/deal";
import { Datepicker } from "flowbite-react";
import { DealNotificationFormType } from "utils/types";
import { classNames, dateToLocalDate, iterableEnum } from "utils/utils";
import { useIsMounted } from "hooks/useIsMounted";
import { SearchState } from "utils/enums";

const initialState: DealNotificationFormType = {
  totalProvisions: 0,
  searcherExpense: 0,
  receptorOne: "",
  receptorOneExpenses: 0,
  receptorTwo: "",
  receptorTwoExpenses: 0,
  jurisdiction1: "",
  jurisdiction2: "",
  searchState: SearchState.Pendiente,
  orderDate: "",
  numberOfContacts: 0,
  lastSearch: "",
};
const requiredFields = ["totalProvisions", "receptor1"];

const DealNotificationForm = ({
  dealId,
  currency,
}: {
  dealId: string;
  currency: string;
}) => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const setAlerta = useSetRecoilState(alert);
  const usrRoute = useRecoilValue(userRoute);

  const [payload, setPayload] = useState({ ...initialState });

  useEffect(() => {
    const getDealNotificationData = async (dId: string) => {
      const d = await getDeal(usrRoute.request, dId, true);
      const dealNot = d?.dealNotification;

      if (dealNot) {
        setPayload({
          id: dealNot.id || "",
          dealId: dealId,
          totalProvisions: dealNot.totalProvisions || 0,
          searcherExpense: dealNot.searcherExpense || 0,
          receptorOne: dealNot.receptorOne || "",
          receptorOneExpenses: dealNot.receptorOneExpenses || 0,
          receptorTwo: dealNot.receptorTwo || "",
          receptorTwoExpenses: dealNot.receptorTwoExpenses || 0,
          jurisdiction1: dealNot.jurisdiction1 || "",
          jurisdiction2: dealNot.jurisdiction2 || "",
          searchState: dealNot.searchState || SearchState.Pendiente,
          orderDate: dealNot.orderDate || "",
          numberOfContacts: dealNot.numberOfContacts || 0,
          lastSearch: dealNot.lastSearch || "",
        });
      }
    };
    if (isMounted()) {
      getDealNotificationData(dealId);
    }
  }, [dealId, isMounted, usrRoute]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const response = await createOrUpdateDealNotification({
      ...payload,
      dealId,
    });

    if (response?.success) {
      setAlerta({
        display: true,
        variant: "success",
        message: "Notificatión de Juicio creada o actualizada con éxito",
      });
      navigate(-1);
    } else {
      setAlerta({
        display: false,
        variant: "error",
        message:
          "Hubo un error creando / actualizando la Notificación de Juicio",
      });
    }
  };

  const handleFieldChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const value = e.currentTarget.value;
      const v =
        value === "" || Number.isNaN(Number(value)) ? value : Number(value);

      const newPayload = {
        ...payload,
        [key]: v,
      };
      setPayload(newPayload);
    };

  const formValid = useMemo(() => {
    const emptyFields =
      Object.entries(payload)
        .filter(([k, _]) => requiredFields.includes(k))
        .filter(([k, v]) => !v).length > 0;

    return !emptyFields;
  }, [payload]);

  const handleSelectChange =
    (key: string) => (e: React.ChangeEvent<HTMLSelectElement>) => {
      const v = Number.isNaN(Number(e.currentTarget.value))
        ? e.currentTarget.value
        : Number(e.currentTarget.value);

      e.preventDefault();
      const newPayload = {
        ...payload,
        [key]: v,
      };

      setPayload(newPayload);
    };

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-12 mt-4">
        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 gap-y-10">
          <div className="sm:col-span-3">
            <label
              htmlFor="total-provisions"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {`Provisión Monto Total  * (${currency.toUpperCase()})`}
            </label>
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  onChange={handleFieldChange("totalProvisions")}
                  name="total-provisions"
                  type="number"
                  id="total-provisions"
                  value={payload.totalProvisions}
                  className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="order-date"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Fecha de encargo
            </label>
            <Datepicker
              language="es-cl"
              id="order-date"
              value={
                payload.orderDate
                  ? dateToLocalDate(payload.orderDate)
                  : undefined
              }
              onSelectedDateChanged={(d) => {
                setPayload({ ...payload, orderDate: d.toUTCString() });
              }}
            />
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="receptor1"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nombre de Receptor 1 *
            </label>
            <div className="mt-2">
              <input
                onChange={handleFieldChange("receptorOne")}
                type="text"
                name="receptor1"
                id="receptor1"
                value={payload.receptorOne}
                autoComplete="receptor1"
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="receptor2"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nombre de Receptor 2
            </label>
            <div className="mt-2">
              <input
                onChange={handleFieldChange("receptorTwo")}
                type="text"
                name="receptor2"
                id="receptor2"
                value={payload.receptorTwo}
                autoComplete="receptor2"
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="receptor1-expenses"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {`Gastos Receptor 1 (${currency.toUpperCase()})`}
            </label>
            <div className="mt-2">
              <input
                onChange={handleFieldChange("receptorOneExpenses")}
                type="number"
                name="receptor1-expenses"
                id="receptor1-expenses"
                value={payload.receptorOneExpenses}
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="receptor2-expenses"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {`Gastos Receptor 2 (${currency.toUpperCase()})`}
            </label>
            <div className="mt-2">
              <input
                onChange={handleFieldChange("receptorTwoExpenses")}
                type="number"
                name="receptor2-expenses"
                id="receptor2-expenses"
                value={payload.receptorTwoExpenses}
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="jurisdiction1"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Jurisdicción 1
            </label>
            <div className="mt-2">
              <input
                onChange={handleFieldChange("jurisdiction1")}
                type="text"
                name="jurisdiction1"
                id="jurisdiction1"
                value={payload.jurisdiction1}
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="jurisdiction2"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Jurisdicción 2
            </label>
            <div className="mt-2">
              <input
                onChange={handleFieldChange("jurisdiction2")}
                type="text"
                name="jurisdiction2"
                id="jurisdiction2"
                value={payload.jurisdiction2}
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="searchState"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Estado de Búsqueda
            </label>
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <select
                  onChange={handleSelectChange("searchState")}
                  name="searchState"
                  id="searchState"
                  value={payload.searchState}
                  className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  {iterableEnum(SearchState).map((dst, idx) => (
                    <option key={`search-state-${dst}`} value={idx}>
                      {dst}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="last-update"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Fecha última búsqueda
            </label>
            <Datepicker
              language="es-cl"
              id="last-searh"
              value={
                payload.lastSearch
                  ? dateToLocalDate(payload.lastSearch) // TODO: Format in friendlier way
                  : undefined
              }
              onSelectedDateChanged={(d) => {
                setPayload({ ...payload, lastSearch: d.toUTCString() });
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-10 flex items-center justify-between">
        <div className="flex gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => {
              navigate(-1);
            }}
          >
            Volver
          </button>
          <button
            type="submit"
            disabled={!formValid}
            className={
              formValid
                ? classNames(
                    "rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  )
                : classNames(
                    "rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  )
            }
          >
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default DealNotificationForm;
