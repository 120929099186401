import { CompanyFormType, CompanyType } from "utils/types";
import { ResponseStatus } from "utils/enums";
import { post, get } from "./utils";

type ListResponse = {
  success: string;
  data: CompanyType[];
  status: ResponseStatus;
};

export const listCompanies = async () => {
  const companies = await get<ListResponse>({
    route: "admin/company/list",
  });

  return companies?.data || [];
};

type CreateResponse = {
  success: string;
  data: CompanyType;
  status: ResponseStatus;
};

export const createCompany = async (payload: CompanyFormType) => {
  const company = await post<CreateResponse>({
    route: "admin/company/create",
    payload,
  });
  return company?.data || null;
};

type GetResponse = CreateResponse & {};
export const getCompany = async (id: string) => {
  const company = await post<GetResponse>({
    route: "admin/company/get",
    payload: {
      id,
    },
  });

  return company?.data || null;
};

export const getOwnCompany = async () => {
  const company = await get<GetResponse>({
    route: "company/get-own",
  });

  return company?.data || null;
};

export const deleteCompany = async (companyId: string) => {
  const res = await post<{ success: boolean }>({
    route: "admin/company/delete",
    payload: {
      companyId,
    },
  });

  return res?.success;
};
