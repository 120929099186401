import { CountryStateType } from "utils/types";
import { ResponseStatus } from "utils/enums";
import { get } from "./utils";

type CourtListResponse = {
  success: boolean;
  data: CountryStateType[];
  status: ResponseStatus;
};

export const listCountryStates = async () => {
  const countries = await get<CourtListResponse>({
    route: "country/list",
  });
  return countries?.data || [];
};
