import { useEffect, useState } from "react";
import { getDeal } from "requests/deal";
import { useNavigate } from "react-router-dom";
import DealDataView from "./Deals/DealDataView";
import DealNotificationView from "./Deals/DealNotificationView";
import NotificationEditor from "components/shared/Notification/Editor";
import Steps from "./Steps";
import { useIsMounted } from "hooks/useIsMounted";
import { useRecoilValue } from "recoil";
import userRoute from "atoms/userRoute";
import { CompanyType, DealType, DealViewState, TabType } from "utils/types";
import { classNames } from "utils/utils";
import DocumentsView from "components/shared/Document";
import { LegalEntityType } from "utils/enums";
import { getOwnCompany } from "requests/company";
import useUrlParams from "hooks/useUrlParams";

const initialState: DealType = {
  id: "",
  dealname: "",
  caseId: "",
  procedure: 0,
  dealstage: 0,
  debtor: "",
  debtorContact: "",
  debtorAddress: "",
  debtorEmail: "",
  debtorTaxId: "",
  debtorEntityType: LegalEntityType.natural,
  totalDebt: 0,
  externalUrl: "",
  comission: 0,
  inDefault: false,
  createdAt: "",
  updatedAt: "",
};

const tabs: TabType[] = [
  { name: "Información", key: "info" },
  { name: "Gastos", key: "dealNotifications" },
  { name: "Documentos", key: "docs" },
  { name: "Actualizaciones", key: "notifications" },
];

const ClientAccount = ({ dealId }: { dealId: string }) => {
  const { dealState } = useUrlParams();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const usrRoute = useRecoilValue(userRoute);

  const setDealState = (key: DealViewState) => {
    navigate(`?dealId=${dealId}&dealState=${key}`);
  };

  const [deal, setDeal] = useState<DealType>({ ...initialState });
  const [comp, setComp] = useState<CompanyType | undefined>(undefined);

  useEffect(() => {
    const getDealData = async (dId: string) => {
      const d = await getDeal(usrRoute.request, dId, true, true, true);
      if (d) {
        setDeal(d);
      }
    };
    if (dealId && isMounted()) {
      getDealData(dealId);
    }
  }, [dealId, isMounted]);

  useEffect(() => {
    const getOwnCompanyData = async () => {
      const c = await getOwnCompany();
      if (c) {
        setComp(c);
      }
    };

    if (deal.company?.id && isMounted()) {
      getOwnCompanyData();
    }
  }, [deal.company?.id, isMounted]);

  return (
    <>
      <Steps deal={deal} />
      <div className="hidden sm:block pt-10">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                onClick={() => setDealState(tab.key)}
                className={classNames(
                  tab.key === dealState
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 pb-4 px-1 text-sm font-medium cursor-pointer"
                )}
                aria-current={tab.key === dealState ? "page" : undefined}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
      <div>
        {dealState === "info" && <DealDataView deal={deal} />}
        {dealState === "dealNotifications" && deal.dealNotification && (
          <DealNotificationView
            dealNotification={deal.dealNotification}
            currency={comp?.countryState?.currency || ""}
          />
        )}
        {dealState === "docs" && <DocumentsView dealId={dealId} />}
        {dealState === "notifications" && (
          <NotificationEditor dealId={dealId} />
        )}
      </div>
      <div className="mt-10 flex items-center justify-end">
        <div className="flex gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => {
              navigate(-1);
            }}
          >
            Volver
          </button>
        </div>
      </div>
    </>
  );
};

export default ClientAccount;
