import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import { CompanyType } from "utils/types";
import CompanyForm from "./Companies/Form";
import { getCompany } from "requests/company";
import DealsView from "./Deals";
import UsersView from "./Users/UsersView";
import useUrlParams from "../../hooks/useUrlParams";

const ClientAccountView = ({
  companyId,
  dealId,
  userId,
}: {
  companyId: string;
  dealId?: string;
  userId?: string;
}) => {
  const { clientState } = useUrlParams();
  const [cmp, setCpm] = useState<CompanyType | null>(null);

  useEffect(() => {
    const getCompanyData = async (comId: string) => {
      const c = await getCompany(comId);
      setCpm(c);
    };
    if (companyId) {
      getCompanyData(companyId);
    }
  }, [companyId]);

  if (!companyId) {
    return (
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 mb-3">
          Company Not Found
        </h2>
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-base font-semibold leading-7 text-gray-900 mb-3">
        {cmp?.name}
      </h2>

      <div className="flex gap-x-8">
        <Sidebar companyId={companyId} />
        <div className="flex-col flex-1">
          {clientState === "account" && <CompanyForm companyId={companyId} />}
          {clientState === "users" && (
            <UsersView companyId={companyId} userId={userId} />
          )}
          {clientState === "deals" && (
            <DealsView companyId={companyId} dealId={dealId} />
          )}
        </div>
      </div>
    </div>
  );
};
export default ClientAccountView;
