import { TaskParsedType } from "utils/types";
import { dateToLocalDate } from "utils/utils";
import EditorRead from "components/shared/EditorRead";
import { OutputData } from "@editorjs/editorjs";
import { PencilIcon } from "@heroicons/react/24/outline";

export default function TaskList({
  tasks,
  getMore,
  empty,
  onEdit,
  editingTask,
}: {
  tasks: TaskParsedType[];
  getMore?: () => void;
  empty: boolean;
  onEdit?: (id: string) => void;
  editingTask: boolean;
}) {
  return (
    <div className="flow-root overflow-y-scroll p-2 flex-1 rounded-xl">
      <ul className="flex flex-col gap-y-4">
        {tasks.map((task) => (
          <li key={task.id} className="">
            <div className="relative p-2">
              <div className="relative flex items-start space-x-3">
                <>
                  <div className="min-w-0 flex-1">
                    <div>
                      <div className="text-sm font-medium text-gray-900">
                        <span>{task.title}</span>
                        {!editingTask && (
                          <button
                            onClick={() => onEdit && onEdit(task.id)}
                            className="ml-2 text-indigo-600 hover:text-indigo-900"
                          >
                            <PencilIcon className="h-3 w-3" />
                          </button>
                        )}
                      </div>

                      <div className="text-sm">
                        {task.assignedUser ? (
                          <a href="/" className="">
                            {`Responsable: ${task.assignedUser.firstName} ${task.assignedUser.lastName}`}
                          </a>
                        ) : (
                          <span>Responable: No asignado</span>
                        )}
                      </div>
                      <span className="text-sm">
                        Estado: {task.completed ? "Completado" : "Pendiente"}
                      </span>
                      <p className="mt-0.5 text-sm text-gray-500">
                        {`Fecha plazo: ${dateToLocalDate(task.due)}`}
                      </p>
                    </div>
                    <div className="mt-2">
                      <EditorRead
                        id={`task-content-${task.id}`}
                        data={task.content as OutputData}
                      />
                    </div>
                  </div>
                </>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="flex flex-row justify-start pl-10 pt-4">
        {Boolean(getMore) ? (
          <button
            onClick={getMore}
            // disabled={!getMore}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Cargar Más
          </button>
        ) : empty ? (
          <span>No hay tareas</span>
        ) : (
          <span>... No hay más tareas</span>
        )}
      </div>
    </div>
  );
}
