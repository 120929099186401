import { Routes, Route } from "react-router-dom";
import DealsTable from "./DealsTable";

const Deals = () => (
  <main className="-mt-32">
    <div className="mx-auto max-w-8xl px-4 pb-12 sm:px-6 lg:px-8">
      <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <Routes>
          <Route path="/" element={<DealsTable />} />
        </Routes>
      </div>
    </div>
  </main>
);

export default Deals;
