import { postFile, post } from "./utils";
import { ResponseStatus } from "utils/enums";
import { DocumentType } from "utils/types";

type DocumentListResponse = {
  data: DocumentType[];
  currentPage: number;
  totalCount: number;
  countPerPage: number;
  status: ResponseStatus;
};
type DocumentCreateResponse = {
  status: ResponseStatus;
};
export const uploadDocument = async (data: FormData) => {
  const res = await postFile<DocumentCreateResponse>({
    route: "document/upload",
    payload: data,
  });

  return res;
};

export const uploadDocuments = async (data: FormData) => {
  const res = await postFile<DocumentCreateResponse>({
    route: "document/uploads",
    payload: data,
  });

  return res;
};

export const listDocuments = async ({
  dealId,
  page,
}: {
  dealId: string;
  page: number;
}) => {
  const documents = await post<DocumentListResponse>({
    route: "document/list",
    payload: {
      dealId,
      page,
    },
  });

  return documents;
};

type GetLinkResponse = {
  url: string;
  msg: string;
  status: ResponseStatus;
};

export const getDocumentLink = async (documentId: string) => {
  const deal = await post<GetLinkResponse>({
    route: `document/get`,
    payload: {
      documentId,
    },
  });

  return deal?.url || null;
};

export const deleteDocument = async (documentId: string) => {
  const res = await post<{ success: boolean }>({
    route: "document/delete",
    payload: {
      documentId,
    },
  });

  return res?.success;
};

export const deleteDocuments = async (documentIds: string[]) => {
  const res = await post<{ success: boolean }>({
    route: "document/delete-multiple",
    payload: {
      documentIds,
    },
  });

  return res?.success;
};
