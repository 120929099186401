import { post } from "./utils";
import { TaskFilter, TaskFormType, TaskType } from "utils/types";
import { ResponseStatus } from "utils/enums";

type TaskListResponse = {
  success: boolean;
  data: TaskType[];
  currentPage: number;
  totalCount: number;
  countPerPage: number;
  status: ResponseStatus;
};

type CreateResponse = {
  success: string;
  data: TaskType;
  status: ResponseStatus;
};

export const createOrUpdateTask = async (payload: TaskFormType) => {
  const response = await post<CreateResponse>({
    route: "admin/task/createOrUpdate",
    payload,
  });

  return response || null;
};

export const listTasksForDeal = async ({
  dealId,
  page,
  includeAssignedUser,
}: {
  dealId: string;
  page: number;
  includeAssignedUser?: boolean;
}) => {
  const tasks = await post<TaskListResponse>({
    route: "admin/task/listForDeal",
    payload: {
      dealId,
      page,
      includeAssignedUser,
    },
  });

  return tasks || null;
};

export const listTasksForParentCompany = async ({
  page,
  includeAssignedUser,
  filter,
}: {
  page: number;
  includeAssignedUser?: boolean;
  filter?: TaskFilter;
}) => {
  const tasks = await post<TaskListResponse>({
    route: "admin/task/listForParentCompany",
    payload: {
      page,
      includeAssignedUser,
      filter,
    },
  });

  return tasks || null;
};

export const deleteTask = async (taskId: string) => {
  const res = await post<{ success: boolean }>({
    route: "admin/task/delete",
    payload: {
      taskId,
    },
  });

  return res?.success;
};
