type Tuple = [number, number];

export const toTuple = ({ x, y }: { x: number; y: number }): Tuple => [y, x];

export const signUpPasswordValid = (pw: string) => {
  // Regular expressions for each condition
  const lengthRegex = /.{8,}/;
  const letterRegex = /[a-zA-Z]/;
  const numberRegex = /\d/;
  // const symbolRegex = /[!@#$%^&*(),.?":{}|<>]/;

  // Check each condition
  const hasLength = lengthRegex.test(pw);
  const hasLetter = letterRegex.test(pw);
  const hasNumber = numberRegex.test(pw);
  // const hasSymbol = symbolRegex.test(pw);

  // Return true if all conditions are met, otherwise false
  return hasLength && hasLetter && hasNumber;
};
export const passwordValid = (pw1: string) => pw1.length >= 8;

export const isRepeatValid = (pw1: string, pw2: string) =>
  passwordValid(pw1) && pw1 === pw2;

export const validateEmail = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const goHome = () => {
  window.location.href = "/";
};

export const goAdmin = () => {
  window.location.href = "/admin";
};

export const iterableEnum = (
  e: any // parameter should be enum
) => Object.keys(e).filter((element) => Number.isNaN(Number(element)));

export const dateToLocalDateTime = (d: string, local?: string) => {
  const newDate = new Date(d);
  const loc = local || "es-CL";
  return `${newDate.toLocaleDateString(loc)} - ${newDate.toLocaleTimeString(
    loc
  )}`;
};

export const dateToLocalDate = (d: string, local?: string) => {
  const newDate = new Date(d);
  const loc = local || "es-CL";
  return `${newDate.toLocaleDateString(loc)}`;
};

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function abbrString(input: string, length?: number): string {
  const l = length || 12;
  const cutSize = length ? Math.floor(l / 3) : 6;

  if (input.length <= (length || 12)) {
    return input;
  } else {
    return `${input.slice(0, cutSize)}..${input.slice(-(cutSize - 2))}`;
  }
}
