import { useCallback, useEffect, useState } from "react";
import { DocumentType } from "utils/types";
import { useIsMounted } from "hooks/useIsMounted";
import {
  deleteDocuments,
  getDocumentLink,
  listDocuments,
} from "requests/document";
import { abbrString, classNames, dateToLocalDate } from "utils/utils";
import Pager from "components/shared/Pager";
import DocumentsFooter from "./DocumentsFooter";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useSetRecoilState, useRecoilValue } from "recoil";
import alert from "atoms/alert";
import confirmModal from "atoms/confirmModal";
import accountInState from "atoms/account";
import {
  DocumentAssociation,
  DocumentAssociationTitle,
  Roles,
} from "utils/enums";
import { Tooltip } from "flowbite-react";

export default function DocumentsTable({ dealId }: { dealId: string }) {
  const userAccount = useRecoilValue(accountInState);

  const [documents, setDocuments] = useState<DocumentType[]>([]);
  const [nextPage, setNextPage] = useState(1);

  const isMounted = useIsMounted();
  const setConfirmModal = useSetRecoilState(confirmModal);
  const setAlerta = useSetRecoilState(alert);
  const [deleteList, setDeleteList] = useState<string[]>([]);

  const [count, setCount] = useState({
    total: 0,
    countPerPage: 1,
    currentPage: 1,
  });

  const listDocs = useCallback(async () => {
    const res = await listDocuments({ dealId, page: nextPage });

    if (res?.data) {
      setDocuments(res.data);
      setCount({
        total: res.totalCount,
        countPerPage: res.countPerPage,
        currentPage: res.currentPage,
      });
    }
  }, [dealId, nextPage]);

  const getDownloadLink = useCallback(async (docId: string) => {
    const link = await getDocumentLink(docId);
    if (link) {
      window.open(link);
    }
  }, []);

  const onDeleteDocuments = useCallback(async () => {
    const deleteSuccess = await deleteDocuments(deleteList);
    if (deleteSuccess) {
      setAlerta({
        display: true,
        variant: "success",
        message: "Documentos borrados con éxito",
      });
      setDeleteList([]);
    } else {
      setAlerta({
        display: true,
        variant: "error",
        message: "Hubo un error al borrar los documentos",
      });
    }
    listDocs();
  }, [deleteList, listDocs, setAlerta]);

  const setDeleteModal = () => {
    setConfirmModal({
      display: true,
      onConfirm: async () => {
        await onDeleteDocuments();
      },
      title: "Borrar documento",
      variant: "alert",
      message: `¿Estas seguro(a) de querer borrar los documentos?`,
    });
  };

  useEffect(() => {
    if (isMounted()) {
      listDocs();
    }
  }, [isMounted, listDocs]);

  return (
    <div className="mt-8 mb-20 px-4 sm:px-6 lg:px-8">
      <div className="flow-root">
        <div className="-mx-4 -my-2 pb-8 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full max-h-screen py-2 align-middle sm:px-6 lg:px-8">
            <div className="flex justify-end">
              {userAccount && userAccount.role === Roles.admin ? (
                <button
                  disabled={!deleteList.length}
                  onClick={setDeleteModal}
                  className={classNames(
                    "relative inline-flex items-center justify-center rounded-md p-2 text-indigo-200 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600",
                    deleteList.length > 0
                      ? "bg-indigo-600 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white"
                      : "bg-gray-600"
                  )}
                >
                  <TrashIcon className="block h-3 w-3" aria-hidden="true" />
                </button>
              ) : null}
            </div>
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {userAccount && userAccount.role === Roles.admin && (
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      <input
                        type="checkbox"
                        onChange={() => {
                          if (deleteList.length === documents.length) {
                            setDeleteList([]);
                          } else {
                            setDeleteList(documents.map((d) => d.id));
                          }
                        }}
                        checked={
                          deleteList.length === documents.length &&
                          deleteList.length > 0
                        }
                        className="relative inline-flex items-center justify-center rounded-md p-2  hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                      />
                    </th>
                  )}
                  <th
                    scope="col"
                    className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                  >
                    Nombre
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Fecha creación
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Creador
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Tipo
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {documents.length ? (
                  documents.map((document) => (
                    <tr key={`file-${document.id}`}>
                      {userAccount && userAccount.role === Roles.admin && (
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                          <input
                            type="checkbox"
                            onChange={() => {
                              const copyList = [...deleteList];
                              const i = copyList.indexOf(document.id);
                              if (i > -1) {
                                copyList.splice(i, 1);
                              } else {
                                copyList.push(document.id);
                              }

                              setDeleteList(copyList);
                            }}
                            checked={deleteList.includes(document.id)}
                            className="relative inline-flex items-center justify-center rounded-md p-2  hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                          />
                        </td>
                      )}
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        <div
                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                          onClick={() => getDownloadLink(document.id)}
                        >
                          <Tooltip
                            hidden={document.name.length <= 40}
                            content={document.name}
                          >
                            {abbrString(document.name, 40)}
                          </Tooltip>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {dateToLocalDate(document.createdAt)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {document.creator?.firstName
                          ? `${document.creator?.firstName} ${document.creator?.lastName}`
                          : "Usuario no asignado"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {
                          DocumentAssociationTitle[
                            DocumentAssociation[document.association]
                          ]
                        }
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <h6 className="pt-8">Tabla Vacía</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="pt-8">
              <Pager
                totalPages={Math.ceil(count.total / count.countPerPage)}
                current={count.currentPage}
                onChangePage={setNextPage}
              />
            </div>
          </div>
        </div>
      </div>
      <DocumentsFooter
        dealId={dealId}
        onUpload={() => {
          setNextPage(1);
          listDocs();
        }}
      />
    </div>
  );
}
