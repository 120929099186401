import { useLocation } from "react-router-dom";
import { AccountViewState, DealViewState } from "../utils/types";

function useUrlParams() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  return {
    companyId: searchParams.get("companyId") || undefined,
    dealId: searchParams.get("dealId") || undefined,
    userId: searchParams.get("userId") || undefined,
    dealState: searchParams.get("dealState") || ("info" as DealViewState),
    clientState:
      searchParams.get("clientState") || ("account" as AccountViewState),
  };
}

export default useUrlParams;
