import DocumentsTable from "./DocumentsTable";

const DocumentsView = ({ dealId }: { dealId: string }) => {
  return (
    <>
      <DocumentsTable dealId={dealId} />
    </>
  );
};

export default DocumentsView;
