import { useRecoilValue } from "recoil";
import account from "atoms/account";
import DealsTable from "components/shared/Deals/DealsTable";
import Header from "components/shared/Deals/Header";
import AccountView from "./AccountView";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export default function ClientRoute() {
  const usrAcc = useRecoilValue(account);
  const location = useLocation();
  const urlParams = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );
  const { dealId } = useMemo(
    () => ({
      dealId: urlParams.get("dealId") || undefined,
    }),
    [urlParams]
  );

  return (
    <main className="-mt-32">
      <div className="mx-auto max-w-8xl px-4 pb-12 sm:px-6 lg:px-8">
        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          {dealId ? (
            <AccountView dealId={dealId} />
          ) : (
            <>
              <Header />
              <DealsTable companyId={usrAcc.companyId} />
            </>
          )}
        </div>
      </div>
    </main>
  );
}
