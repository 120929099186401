import AdminClientAccountView from "./AdminClientAccountView";
import TasksTable from "components/admin/Tasks/Table";
import { Roles } from "utils/enums";
import { useRecoilValue } from "recoil";
import accountInState from "atoms/account";
import useUrlParams from "hooks/useUrlParams";

const AdminPanel = () => {
  const user = useRecoilValue(accountInState);

  const { companyId, dealId, userId } = useUrlParams();

  return (
    <main className="-mt-32">
      <div className="mx-auto max-w-8xl px-4 pb-12 sm:px-6 lg:px-8">
        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          {companyId ? (
            <AdminClientAccountView
              companyId={companyId}
              dealId={dealId}
              userId={userId}
            />
          ) : user.role === Roles.admin ? (
            <TasksTable />
          ) : (
            <div>Dashboard de Superadmin</div>
          )}
        </div>
      </div>
    </main>
  );
};

export default AdminPanel;
