import { useMemo } from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Procedure, ProceduresTitles, ProcedureToDealstage } from "utils/enums";
import { DealType } from "utils/types";

export default function Steps({ deal }: { deal: DealType }) {
  const procedureTitle =
    deal.procedure !== undefined
      ? ProceduresTitles[Procedure[deal.procedure]]
      : "";

  const dealstageTitles = useMemo(
    () =>
      deal.procedure !== undefined
        ? ProcedureToDealstage[Procedure[deal.procedure]]
        : [],
    [deal.procedure]
  );

  const steps = useMemo(
    () =>
      dealstageTitles.map((dealTitle, idx) => {
        const status =
          idx === deal.dealstage
            ? "current"
            : idx < (deal.dealstage ?? 0)
            ? "complete"
            : "upcoming";

        return {
          id: idx + 1,
          name: dealTitle,
          status,
        };
      }),

    [deal.dealstage, dealstageTitles]
  );

  return (
    <>
      <h2 className="text-lg pb-2">{procedureTitle}</h2>
      <nav aria-label="Progress">
        <ol
          role="list"
          className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
        >
          {steps.map((step, stepIdx) => (
            <li key={step.name} className="relative md:flex md:flex-1">
              {step.status === "complete" ? (
                <div className="group flex w-full items-center ">
                  <span className="flex items-center pl-2 pr-3 py-3 text-xs font-medium">
                    <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600">
                      <CheckIcon
                        className="h-3 w-3 text-white"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-2 text-xs font-medium text-gray-900">
                      {step.name}
                    </span>
                  </span>
                </div>
              ) : step.status === "current" ? (
                <div
                  className="flex items-center pl-2 pr-3 py-3 text-xs font-medium"
                  aria-current="step"
                >
                  <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                    <span className="text-indigo-600">{step.id}</span>
                  </span>
                  <span className="ml-2 text-xs font-medium text-indigo-600">
                    {step.name}
                  </span>
                </div>
              ) : (
                <div className="group flex items-center">
                  <span className="flex items-center pl-2 pr-3 py-3 text-xs font-medium">
                    <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300">
                      <span className="text-gray-500">{step.id}</span>
                    </span>
                    <span className="ml-2 text-xs font-medium text-gray-500">
                      {step.name}
                    </span>
                  </span>
                </div>
              )}

              {stepIdx !== steps.length - 1 ? (
                <>
                  {/* Arrow separator for lg screens and up */}
                  <div
                    className="absolute right-0 top-0 hidden h-full w-5 md:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          ))}
        </ol>
      </nav>
    </>
  );
}
