import { post } from "./utils";
import {
  DealType,
  DealFormType,
  DealNotificationFormType,
  UserType,
  CompanyType,
} from "utils/types";
import { ResponseStatus } from "utils/enums";

type DealListResponse = {
  success: boolean;
  data: DealType[];
  status: ResponseStatus;
  totalCount: number;
  currentPage: number;
  countPerPage: number;
};

// TODO: This is a general endpoint that responds differently to different users
export const listDeals = async ({
  requestRoute = "",
  companyId,
  includeCourt,
  includeManager,
  page,
  filter,
}: {
  requestRoute?: string;
  companyId?: string;
  includeCourt?: boolean;
  includeManager?: boolean;
  page: number;
  filter: any;
}) => {
  const deals = await post<DealListResponse>({
    route: `${requestRoute}deal/list`,
    payload: {
      companyId,
      includeCourt,
      includeManager,
      page,
      filter,
    },
  });
  return deals || null;
};

type CreateResponse = {
  success: string;
  data: DealType;
  status: ResponseStatus;
};

export const createOrUpdateDeal = async (payload: DealFormType) => {
  const response = await post<CreateResponse>({
    route: "admin/deal/createOrUpdate",
    payload,
  });

  return response || null;
};

export const createOrUpdateDealNotification = async (
  payload: DealNotificationFormType
) => {
  const response = await post<CreateResponse>({
    route: "admin/deal/createOrUpdateNotification",
    payload,
  });

  return response || null;
};

type GetResponse = CreateResponse & {};

export const getDeal = async (
  requestRoute = "",
  dealId: string,
  includeNotification?: boolean,
  includeCourt?: boolean,
  includeManager?: boolean
) => {
  const deal = await post<GetResponse>({
    route: `${requestRoute}deal/get`,
    payload: {
      dealId,
      includeNotification,
      includeCourt,
      includeManager,
    },
  });

  return deal?.data || null;
};

type GetManagerResponse = {
  success: string;
  data: UserType;
  status: ResponseStatus;
};

type GetCompanyResponse = {
  success: string;
  data: CompanyType;
  status: ResponseStatus;
};

export const getDealManager = async (dealId: string) => {
  const user = await post<GetManagerResponse>({
    route: "deal/get-manager",
    payload: {
      dealId,
    },
  });

  return user?.data || null;
};

export const getDealCompany = async (dealId: string) => {
  const c = await post<GetCompanyResponse>({
    route: "admin/deal/get-company",
    payload: {
      dealId,
    },
  });

  return c?.data || null;
};

export const getDealNotification = async (
  requestRoute = "",
  dealNotificationId: string
) => {
  const deal = await post<GetResponse>({
    route: `${requestRoute}deal/get`,
    payload: {
      dealNotificationId,
    },
  });

  return deal?.data || null;
};

export const deleteDeal = async (dealId: string) => {
  const res = await post<{ success: boolean }>({
    route: "admin/deal/delete",
    payload: {
      dealId,
    },
  });

  return res?.success;
};
