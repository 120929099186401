import { default as React, useState, useCallback, useEffect } from "react";
import { OutputData } from "@editorjs/editorjs/types/data-formats/output-data";
import alert from "atoms/alert";
import Editor from "../Editor";
import {
  createOrUpdateNotification,
  listNotifications,
} from "requests/notifications";
import { NotificationFormType, NotificationParsedType } from "utils/types";
import NotificationList from "./NotificationList";
import { ResponseStatus } from "utils/enums";
import { classNames } from "utils/utils";
import Pager from "../Pager";
import { useIsMounted } from "hooks/useIsMounted";
import { useSetRecoilState } from "recoil";

const DEFAULT_INITIAL_DATA = () => {
  return {
    time: new Date().getTime(),
    blocks: [
      {
        type: "header",
        data: {
          text: "",
          level: 1,
        },
      },
    ],
  } as OutputData;
};

const NotificationEditor = ({ dealId }: { dealId: string }) => {
  const [editorData, setEditorData] = useState(DEFAULT_INITIAL_DATA);
  const [dirty, setDirty] = useState(false);
  const [nots, setNots] = useState<NotificationParsedType[]>([]);
  const [nextPage, setNextPage] = useState(1);
  const setAlerta = useSetRecoilState(alert);

  const isMounted = useIsMounted();
  const [count, setCount] = useState({
    total: 0,
    countPerPage: 1,
    currentPage: 1,
  });

  const listNots = useCallback(async () => {
    const res = await listNotifications({
      dealId,
      page: nextPage,
      includeOwner: true,
    });

    if (res) {
      setCount({
        total: res.totalCount,
        countPerPage: res.countPerPage,
        currentPage: res.currentPage,
      });
    }

    const parsedNots = res?.data.map((n) => ({
      ...n,
      content: JSON.parse(n.content),
    })) as NotificationParsedType[];

    setNots(parsedNots);
  }, [dealId, nextPage]);

  useEffect(() => {
    if (isMounted()) {
      listNots();
    }
  }, [isMounted, listNots]);

  const handleSave = useCallback(async () => {
    const payload = {
      dealId,
      content: JSON.stringify(editorData),
    } as NotificationFormType;

    const res = await createOrUpdateNotification(payload);

    if (res?.success) {
      setEditorData(DEFAULT_INITIAL_DATA); // No effect on editor because editor not controlled
      setDirty(false);

      // NOTE: When adding message reset everything and reload
      setNextPage(1);
      listNots();
      setAlerta({
        display: true,
        variant: "success",
        message: "Mensaje enviado",
      });
    } else {
      const msg =
        res?.status === ResponseStatus.Error &&
        res.msg === "Deal has no owner or case manager"
          ? "Juicio no tiene usuario o abogado encargado"
          : "Error al enviar mensaje";

      setAlerta({
        display: true,
        variant: "error",
        message: msg,
      });
    }
  }, [dealId, editorData]);

  const onChange: React.Dispatch<React.SetStateAction<OutputData>> =
    useCallback((data: React.SetStateAction<OutputData>) => {
      setDirty(true);
      setEditorData(data);
    }, []);

  return (
    <div>
      <div className="flex gap-x-6">
        <div className="w-1/2 max-w-lg">
          <h2 className="text-lg mt-2">Mensajes</h2>
          <NotificationList nots={nots} empty={!Boolean(count.total)} />
        </div>
        <div className="felx-col w-1/2 max-w-md">
          <h2 className="text-lg mt-2">Nuevo mensaje</h2>

          <Editor setEditorData={onChange} dirty={dirty} dealId={dealId} />
          <div className="flex justify-end mt-2">
            <button
              onClick={handleSave}
              disabled={!dirty}
              className={classNames(
                dirty
                  ? "rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  : "rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              )}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between pt-2">
        <Pager
          totalPages={Math.ceil(count.total / count.countPerPage)}
          current={count.currentPage}
          onChangePage={setNextPage}
        />
      </div>
    </div>
  );
};

export default NotificationEditor;
