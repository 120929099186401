export enum Roles {
  clientGuest = 10, // read only acreedor
  client = 20, // acreedor user
  internal = 30, // Lawyers read only
  admin = 40, // Company (Lawyers) user with all permissions
  superadmin = 50, // Lawgico tech staff
}

export enum RoleSp {
  "Cliente invitado" = 10, // read only acreedor
  "Cliente" = 20, // acreedor user
  "Admin invitado" = 30, // Lawyers read only
  "Admin" = 40, // Company (Lawyers) user with all permissions
  "Super Admin" = 50, // Lawgico tech staff
}

export enum CompanyRole {
  payee = 10, // Acreedor
  buffet = 20, // Lawyer buffet
  master = 30, // Master company (only one  / Lawgico)
}

export enum Procedure {
  ExecutiveTrial = 0,
  OrdinaryTrial = 1,
  PreparatoryManagement = 2,
}

export const ProcedureByTitle: { [index: string]: number } = {
  "Juicio Executivo": Procedure.ExecutiveTrial,
  "Juicio Ordinario": Procedure.OrdinaryTrial,
  "Gestion Preparatoria": Procedure.PreparatoryManagement,
};

export const ProceduresTitles: { [index: string]: string } = {
  ExecutiveTrial: "Juicio Executivo",
  OrdinaryTrial: "Juicio Ordinario",
  PreparatoryManagement: "Gestion Preparatoria",
};

export enum ResponseStatus {
  Ok = "ok",
  BadRequest = "bad_request",
  Unauthorized = "unauthorized",
  Error = "server_error",
}

export enum LegalEntityType {
  natural = 1, // persona natural
  corporate = 2, // persona jurídica
}

export const LegalEntityTypeTitle: { [index: string]: string } = {
  natural: "Natural",
  corporate: "Jurídica",
};

// These work as iterable Enums. Don't change the order

export const PreparatoryManagementState = [
  "Pendiente redacción / Firma",
  "Sube demanda / Cumple lo ordenado / Da curso",
  "Encarga notificación / Búsquedas / Oficios",
  "Notificación demanda",
  "Audiencia (Citación Confesión Deuda) / Recursos",
  "Impugnación / Traslado",
  "Prueba impugnación",
  "Certificación",
  "Sentecia",
  "Cerrada (avenimiento, rechazada o fustrada)",
];

export const OrdinaryTrialState = [
  "Pendiente redacción / Firma",
  "Sube demanda / Cumple lo ordenado",
  "Encarga notificación / Búsquedas / Oficios",
  "Notificación",
  "Excepciones / Traslado / incidentes",
  "Audiencia de Conciliación",
  "Prueba ",
  "Sentencia / Cumplimiento incidental",
  "Recursos",
  "Ganado - comisión",
  "Terminada Perdida o frustada",
];

export const ExecutiveTrialState = [
  "Inicio Demanda",
  "Notificación Búsqueda",
  "Notificación Requerimiento",
  "Excepciones",
  "Sentencia",
  "Apremio",
  "Embargos",
  "Remate",
  "Recursos",
  "Termino Juicio",
];

export const ProcedureToDealstage: { [index: string]: string[] } = {
  ExecutiveTrial: ExecutiveTrialState,
  OrdinaryTrial: OrdinaryTrialState,
  PreparatoryManagement: PreparatoryManagementState,
};

export enum TaskPriority { // Mapped to TaskPriority enum in the backend
  "Baja" = 10,
  "Alta" = 20,
  "Plazo fatal" = 30,
}

// Reverse lookup
export const TaskPriorityMap: { [index: string]: number } = {
  Baja: 10,
  Alta: 20,
  "Plazo fatal": 30,
};

export enum SearchState { // Mapped to SearchState enum in the backend
  Pendiente,
  Notificada,
  Positiva,
  Negativa,
}

// Reverse lookup
export const SearchStateMap: { [index: string]: number } = {
  Pendiente: 0,
  Notificada: 1,
  Positiva: 2,
  Negativa: 3,
};

export enum DocumentAssociation {
  "standalone",
  "notification",
}

export const DocumentAssociationTitle: { [index: string]: string } = {
  standalone: "Archivo Suelto",
  notification: "Archivo de Notificación",
};
