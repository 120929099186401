import { classNames } from "utils/utils";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";

const Pager = ({
  current,
  totalPages,
  onChangePage,
}: {
  current: number;
  totalPages: number;
  onChangePage: (p: number) => void;
}) => {
  return (
    <div className="flex align-center justify-end gap-x-2">
      <span className="">{`Página ${
        Number.isInteger(current) ? current : "-"
      } de ${Number.isInteger(totalPages) ? totalPages : "-"}`}</span>
      <>
        <button
          disabled={current <= 1}
          onClick={() => onChangePage(current - 1)}
        >
          <ChevronLeftIcon
            className={classNames(
              "block h-6 w-6",
              current <= 1 ? "text-gray-400" : ""
            )}
            aria-hidden="true"
          />
        </button>
        <button
          disabled={current >= totalPages}
          onClick={() => onChangePage(current + 1)}
        >
          <ChevronRightIcon
            className={classNames(
              "block h-6 w-6",
              current >= totalPages ? "text-gray-400" : ""
            )}
            aria-hidden="true"
          />
        </button>
      </>
    </div>
  );
};

export default Pager;
