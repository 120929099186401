import { useEffect, useMemo, useState } from "react";
import account from "atoms/account";
import alert from "atoms/alert";
import { classNames, validateEmail } from "utils/utils";
import { useRecoilState, useSetRecoilState } from "recoil";
import { login, requestPasswordReset, resendConfirm } from "requests/auth";
import Alert from "components/shared/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import { Roles } from "../utils/enums";

export default function Example() {
  const { search } = useLocation();
  const navigate = useNavigate();

  const [formMode, setFormMode] = useState<
    "login" | "password" | "resend-confirm"
  >("login");
  const setUserAccount = useSetRecoilState(account);
  const [alerta, setAlert] = useRecoilState(alert);

  const [formEmail, setFormEmail] = useState("");
  const [formPassword, setFormPassword] = useState("");

  const resetForm = () => {
    setFormEmail("");
    setFormPassword("");
  };

  useEffect(() => {
    resetForm();
  }, [formMode]);

  const clearMessage = () => {
    setAlert({ display: false, variant: "success", message: "" });
  };

  const handleFormEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailAddress = e.currentTarget.value;
    setFormEmail(emailAddress);
  };

  const handleFormPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.currentTarget.value;
    setFormPassword(password);
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    const payload = {
      email: formEmail,
      password: formPassword || "",
    };
    let data;
    if (formMode === "password") {
      data = await requestPasswordReset(payload);
      if (data?.success) {
        setAlert({
          display: true,
          variant: "success",
          message: `Email de recuperación de contraseña enviado a ${data.email}.`,
        });
      } else {
        setAlert({
          display: true,
          variant: "error",
          message:
            data?.msg ||
            "No se pudo enviar el email de recuperación de contraseña",
        });
      }
    } else if (formMode === "resend-confirm") {
      data = await resendConfirm(payload);
      if (data?.success) {
        setAlert({
          display: true,
          variant: "success",
          message: `Email de confirmación de usuario enviado a ${data.email}.`,
        });
      } else {
        setAlert({
          display: true,
          variant: "error",
          message: data?.msg || "No se pudo enviar el email de confirmación",
        });
      }
    } else {
      data = await login(payload);
      if (data?.success) {
        localStorage.setItem("lawgicoToken", data.token);
        setAlert({
          display: true,
          variant: "success",
          message: `Log in exitoso`,
        });

        setUserAccount({
          userId: data.id,
          email: data.email,
          role: data.role,
          companyId: "", // We get it later from getUser
        });
        if (search) {
          // NOTE: If search present in login url, it means the user went to a url directly
          // without a session. We route them to that url when succesfully logged in
          const userType = data.role > Roles.client ? "admin" : "acreedor";
          navigate(`/${userType}${search}`);
        } else {
          navigate("/");
        }
      } else {
        setAlert({
          display: true,
          variant: "error",
          message: data?.msg || "Error de log in",
        });
      }
    }
  };

  const formValid = useMemo(
    () =>
      formMode === "login"
        ? validateEmail(formEmail) && formPassword
        : validateEmail(formEmail),
    [formEmail, formPassword, formMode]
  );

  return (
    <>
      {alerta.display && <Alert />}
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-sm space-y-10">
          <div>
            <img
              className="mx-auto h-10 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt="Lawgico Logo"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Entra en tu cuenta
            </h2>
          </div>
          <form
            onSubmit={handleSubmit}
            className="space-y-6"
            action="#"
            method="POST"
          >
            <div className="relative -space-y-px rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Dirección Email
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  onFocus={clearMessage}
                  value={formEmail}
                  onChange={handleFormEmail}
                  // disabled={validateEmail(formEmail)}
                  required
                  className="relative block w-full rounded-t-md border-0 pl-3 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="ejemplo@email.com"
                />
              </div>
              {formMode === "login" && (
                <>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Contraseña
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      onFocus={clearMessage}
                      value={formPassword}
                      onChange={handleFormPassword}
                      autoComplete="current-password"
                      required
                      className="relative block w-full rounded-b-md border-0 pl-3 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Contraseña"
                    />
                  </div>

                  <label htmlFor="" className="sr-only">
                    Your password must contain at least 8 characters, including
                    at least one letter and one number.
                  </label>
                </>
              )}
            </div>

            <div>
              <button
                type="submit"
                disabled={!formValid}
                className={classNames(
                  formValid
                    ? "flex w-full justify-center rounded-md bg-indigo-600 px-3 pl-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    : "flex w-full justify-center rounded-md bg-gray-600 px-3 pl-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                )}
              >
                {formMode === "login"
                  ? "Entrar"
                  : formMode === "password"
                  ? "Reestablecer contraseña"
                  : "Reenviar email de confimación"}
              </button>
            </div>
            <div className="flex items-center justify-between">
              {formMode === "login" ? (
                <>
                  <div className="text-sm leading-6">
                    <div
                      onClick={() => setFormMode("password")}
                      className="font-semibold text-indigo-600 hover:text-indigo-500 pb-2"
                    >
                      Olvidaste tu contraseña?
                    </div>
                    <div className="text-sm leading-6">
                      <div
                        onClick={() => setFormMode("resend-confirm")}
                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                      >
                        Reenviar email de confirmación
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="text-sm leading-6">
                  <div
                    onClick={() => setFormMode("login")}
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Entrar
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
