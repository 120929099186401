import { DealNotificationType } from "utils/types";

import { dateToLocalDate } from "utils/utils";
import { SearchState } from "utils/enums";

const DealNotificationView = ({
  dealNotification,
  currency,
}: {
  dealNotification: DealNotificationType;
  currency: string;
}) => {
  return (
    <div className="space-y-12 mt-4">
      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 gap-y-10">
        <div className="sm:col-span-3">
          <label
            htmlFor="total-provisions"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Provisión Monto Total
          </label>
          <div className="mt-2">
            <span className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-gray-100">
              {`${
                dealNotification.totalProvisions
              } (${currency.toUpperCase()})`}
            </span>
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="order-date"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Fecha de Encargo
          </label>
          <div className="mt-2">
            <span className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-gray-100">
              {dateToLocalDate(dealNotification.orderDate)}
            </span>
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="receptor-one"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Nombre de Receptor 1
          </label>
          <div className="mt-2">
            <span className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-gray-100">
              {dealNotification.receptorOne}
            </span>
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="receptor-two"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Nombre de Receptor 2
          </label>
          <div className="mt-2">
            <span className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-gray-100">
              {dealNotification.receptorTwo}
            </span>
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="recepto-one-expenses"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Gastos Receptor 1
          </label>
          <div className="mt-2">
            <span className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-gray-100">
              {`${
                dealNotification.receptorOneExpenses
              } (${currency.toUpperCase()})`}
            </span>
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="receptor-two-expenses"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Gastos Receptor 2
          </label>
          <div className="mt-2">
            <span className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-gray-100">
              {`${
                dealNotification.receptorTwoExpenses
              } (${currency.toUpperCase()})`}
            </span>
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="jurisdiction2"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Jurisdicción 1
          </label>
          <div className="mt-2">
            <span className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-gray-100">
              {dealNotification.jurisdiction1}
            </span>
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="jurisdiction1"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Jurisdicción 2
          </label>
          <div className="mt-2">
            <span className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-gray-100">
              {dealNotification.jurisdiction2}
            </span>
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="searchState"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Estado
          </label>
          <div className="mt-2">
            <span className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-gray-100">
              {SearchState[dealNotification.searchState]}
            </span>
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="lastSearch"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Fecha última búsqueda
          </label>
          <div className="mt-2">
            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md bg-gray-100">
              <span className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 bg-gray-100">
                {dateToLocalDate(dealNotification.lastSearch)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealNotificationView;
