"use client";
import { default as React, useState, useCallback, useEffect } from "react";
import { OutputData } from "@editorjs/editorjs/types/data-formats/output-data";
import alert from "atoms/alert";
import Editor from "components/shared/Editor";
import { createOrUpdateTask } from "requests/task";
import { TaskFormType, TaskParsedType, UserType } from "utils/types";
import { classNames, dateToLocalDate, iterableEnum } from "utils/utils";
import { Datepicker } from "flowbite-react";
import { useSetRecoilState } from "recoil";
import { listSiblingUsers } from "requests/user";
import { useIsMounted } from "hooks/useIsMounted";
import { TaskPriority } from "utils/enums";
import EditorUpdate from "components/shared/EditorUpdate";

const DEFAULT_INITIAL_DATA = () => {
  return {
    time: new Date().getTime(),
    blocks: [
      {
        type: "header",
        data: {
          text: "",
          level: 1,
        },
      },
    ],
  } as OutputData;
};

const defaultPayload = {
  assignedUserId: "",
  title: "",
  content: "",
  priority: TaskPriority["Baja"],
  due: new Date().toDateString(),
  completed: false,
};

const TaskForm = ({
  dealId,
  onSaveCallback,
  onCancelEdit,
  task,
}: {
  dealId: string;
  onSaveCallback: () => void;
  onCancelEdit: () => void;
  task?: TaskParsedType;
}) => {
  const [editorData, setEditorData] = useState(DEFAULT_INITIAL_DATA);
  const [dirty, setDirty] = useState(false);
  const [payload, setPayload] = useState<TaskFormType>(defaultPayload);
  const [users, setUsers] = useState<UserType[]>([]);
  const setAlerta = useSetRecoilState(alert);

  const isMounted = useIsMounted();

  const getUsers = useCallback(async () => {
    const usrs = await listSiblingUsers(); // Lawyers
    setUsers(usrs);
  }, []);

  useEffect(() => {
    if (isMounted()) {
      getUsers();
    }
  }, [getUsers, isMounted]);

  useEffect(() => {
    if (task && task.content) {
      setPayload({
        assignedUserId: task.assignedUser?.id || "",
        title: task.title || "",
        // content: task.content || "",
        priority: task.priority || TaskPriority["Baja"],
        due: task.due
          ? new Date(task.due).toDateString()
          : new Date().toDateString(),
        completed: task.completed,
      });
      setEditorData(task.content);
    }
  }, [task]);

  const handleSelectChange =
    (key: string) => (e: React.ChangeEvent<HTMLSelectElement>) => {
      const v = Number.isNaN(Number(e.currentTarget.value))
        ? e.currentTarget.value
        : Number(e.currentTarget.value);

      e.preventDefault();
      const newPayload = {
        ...payload,
        [key]: v,
      };
      setDirty(true);
      setPayload(newPayload);
    };

  const handleFieldChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      const newPayload = {
        ...payload,
        [key]: e.currentTarget.value,
      };
      setDirty(true);
      setPayload(newPayload);
    };

  const handleCheckboxChange = useCallback(
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newPayload = {
        ...payload,
        [key]: e.currentTarget.checked,
      };
      setDirty(true);
      setPayload(newPayload);
    },
    [payload]
  );

  const handleSave = useCallback(async () => {
    const res = await createOrUpdateTask({
      ...payload,
      id: task?.id,
      dealId: dealId,
      content: JSON.stringify(editorData),
    });

    if (res?.success) {
      setEditorData(DEFAULT_INITIAL_DATA); // No effect on editor because editor not controlled
      setDirty(false);
      setPayload(defaultPayload);

      setAlerta({
        display: true,
        variant: "success",
        message: `Tarea ${task ? "actualizada" : "creada"} con éxito`,
      });
      onSaveCallback();
    } else {
      setAlerta({
        display: true,
        variant: "error",
        message: `Error al ${task ? "actualizar" : "crear"} la tarea`,
      });
    }
  }, [dealId, editorData, onSaveCallback, payload, setAlerta, task]);

  const onEditorChange: React.Dispatch<React.SetStateAction<OutputData>> =
    useCallback((data: React.SetStateAction<OutputData>) => {
      setDirty(true);
      setEditorData(data);
    }, []);

  return (
    <div>
      <div className="grid max-w-xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 gap-y-10">
        <div className="sm:col-span-3">
          <label
            htmlFor="title"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Titulo
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="title"
              id="title"
              value={payload.title}
              onChange={handleFieldChange("title")}
              className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="fecha"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Fecha
          </label>
          <Datepicker
            minDate={new Date()}
            language="es-cl"
            id="last-searh"
            value={
              payload.due
                ? dateToLocalDate(payload.due) // TODO: Format in friendlier way
                : undefined
            }
            onSelectedDateChanged={(d) => {
              setPayload({ ...payload, due: d.toUTCString() });
              setDirty(true);
            }}
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="assignedUser"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Responsable
          </label>
          <div className="mt-2">
            <select
              id="assignedUser"
              name="assignedUser"
              value={payload.assignedUserId}
              onChange={handleSelectChange("assignedUserId")}
              className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value=""></option>

              {users.map((u) => (
                <option key={`${u.id}`} value={u.id}>
                  {`${u.firstName} ${u.lastName}`}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="prioridad"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Prioridad
          </label>
          <div className="mt-2">
            <select
              id="priority"
              name="priority"
              value={payload.priority}
              onChange={handleSelectChange("priority")}
              className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              {iterableEnum(TaskPriority).map((k) => (
                <option
                  key={k}
                  value={TaskPriority[k as keyof typeof TaskPriority]}
                >
                  {k}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="completed"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Completada
          </label>
          <div className="mt-2">
            <input
              // id="completed"
              // name="completed"
              type="checkbox"
              checked={Boolean(payload.completed)}
              onChange={handleCheckboxChange("completed")}
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
          </div>
        </div>
      </div>

      <div className="flex-col pt-10 max-w-xl">
        <h3 className="text-md">Descripción</h3>
        {task ? (
          <EditorUpdate
            setEditorData={onEditorChange}
            dirty={dirty}
            initialEditorData={task.content}
          />
        ) : (
          <Editor setEditorData={onEditorChange} dirty={dirty} />
        )}
        <div className="flex flex-row justify-end pt-2 gap-x-2">
          {task && (
            <button
              onClick={() => {
                onCancelEdit();
                setDirty(false);
                setPayload(defaultPayload);
              }}
              className={classNames(
                "rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              )}
            >
              Cancelar
            </button>
          )}
          <button
            onClick={handleSave}
            disabled={!dirty}
            className={classNames(
              dirty
                ? "rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                : "rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            )}
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskForm;
