import { default as React, useEffect, useRef } from "react";
import EditorJS, { EditorConfig, OutputData } from "@editorjs/editorjs";
import Header from "@editorjs/header";
import AttachesTool from "@editorjs/attaches";

import {
  EDITOR_UPDATE_HOLDER_ID,
  MAX_FILE_SIZE_MB,
  allowedFiles,
  apiUrl,
} from "utils/constants";
export const DEFAULT_INITIAL_DATA = () => {
  return {
    time: new Date().getTime(),
    blocks: [
      {
        type: "header",
        data: {
          text: "",
          level: 1,
        },
      },
    ],
  } as OutputData;
};
const Editor = ({
  setEditorData,
  dirty,
  dealId,
  initialEditorData,
}: {
  setEditorData: React.Dispatch<React.SetStateAction<OutputData>>;
  dirty: boolean;
  dealId?: string;
  initialEditorData: OutputData;
}) => {
  const ejInstance = useRef<EditorJS | null>();

  // Only first time
  useEffect(() => {
    if (!ejInstance.current) {
      initEditor();
    }
    return () => {
      ejInstance?.current?.destroy();
      ejInstance.current = null;
    };
  }, []);

  useEffect(() => {
    if (!dirty) {
      if (ejInstance.current) {
        ejInstance.current.clear();
      }
    }
  }, [dirty]);

  const initEditor = () => {
    const jwtToken = localStorage.getItem("lawgicoToken") || "";

    let tools: EditorConfig["tools"] = {
      header: Header,
    };

    if (dealId) {
      tools = {
        ...tools,
        attaches: {
          class: AttachesTool,
          config: {
            endpoint: `${apiUrl}/document/upload?dealId=${dealId}`,
            additionalRequestHeaders: {
              Authorization: jwtToken,
            },
            buttonText: "Carga un documento",
            errorMessage: `Hubo un error cargando el documento. Archivo debe ser menor a ${MAX_FILE_SIZE_MB}MB y tener formato válido: ${allowedFiles.join(
              ", "
            )}`,
          },
        },
      };
    }

    const editor = new EditorJS({
      holder: EDITOR_UPDATE_HOLDER_ID,
      placeholder: "Tu mensaje...",
      //   logLevel: "Error",
      data: initialEditorData,
      //   data: getEditorData && getEditorData(),
      onReady: () => {
        ejInstance.current = editor;
      },
      onChange: async (api) => {
        let content = await api.saver.save();
        // Avoid onChange when clearing the rich textfield above
        if (content.blocks.length) {
          setEditorData(content);
        }
      },
      minHeight: 60,
      tools,
    });
  };

  return (
    <React.Fragment>
      <div
        className="border border-blue-50 rounded-xl p-2 mt-8 disable-tailwind-font-size"
        id={EDITOR_UPDATE_HOLDER_ID}
      >
        {" "}
      </div>
    </React.Fragment>
  );
};

export default Editor;
