import DealsTable from "components/shared/Deals/DealsTable";
import { CompanyType, DealType, DealViewState, TabType } from "utils/types";
import { useEffect, useState } from "react";
import Form from "./Form";
import Header from "./Header";
import NotificationEditor from "components/shared/Notification/Editor";
import TaskView from "components/admin/Tasks";
import { classNames } from "utils/utils";
import DocumentsView from "components/shared/Document";
import NotificationForm from "./DealNotificationForm";
import { useIsMounted } from "hooks/useIsMounted";
import { useRecoilValue } from "recoil";
import userRoute from "atoms/userRoute";
import { getDeal } from "requests/deal";
import { getCompany } from "requests/company";
import useUrlParams from "components/../hooks/useUrlParams";
import { useNavigate } from "react-router-dom";

const tabs: TabType[] = [
  { name: "Información", key: "info" },
  { name: "Gastos", key: "dealNotifications" },
  { name: "Documentos", key: "docs" },
  { name: "Actualizaciones", key: "notifications" },
  { name: "Tareas", key: "tasks" },
];

export default function DealsView({
  companyId,
  dealId,
}: {
  companyId: string;
  dealId?: string;
}) {
  return dealId ? (
    <DealView companyId={companyId} dealId={dealId} />
  ) : (
    <>
      <Header companyId={companyId} />
      <DealsTable companyId={companyId} />
    </>
  );
}

function DealView({
  companyId,
  dealId,
}: {
  companyId: string;
  dealId: string;
}) {
  const { dealState, clientState } = useUrlParams();
  const navigate = useNavigate();

  const [deal, setDeal] = useState<DealType | undefined>(undefined);
  const [comp, setComp] = useState<CompanyType | undefined>(undefined);
  const usrRoute = useRecoilValue(userRoute);
  const isMounted = useIsMounted();

  const setDealState = (key: DealViewState) => {
    navigate(
      `/${usrRoute.navigation}?companyId=${companyId}&dealId=${dealId}&${
        usrRoute.navigation === "admin" ? `clientState=${clientState}` : ""
      }&dealState=${key}`
    );
  };

  useEffect(() => {
    const getDealData = async (dId: string) => {
      const d = await getDeal(usrRoute.request, dId, false, true, true);
      if (d) {
        setDeal(d);
      }
    };

    if (dealId && dealId !== "new" && isMounted()) {
      getDealData(dealId);
    }
  }, [dealId, isMounted, usrRoute]);

  useEffect(() => {
    const getCompanyData = async (comId: string) => {
      const c = await getCompany(comId);
      if (c) {
        setComp(c);
      }
    };

    if (companyId && isMounted()) {
      getCompanyData(companyId);
    }
  }, [companyId, isMounted]);

  if (!comp) {
    return <div>Loading ...</div>;
  }

  if (dealId === "new") {
    return <Form company={comp} />;
  }

  return (
    <div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                onClick={() => setDealState(tab.key)}
                className={classNames(
                  tab.key === dealState
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 pb-4 px-1 text-sm font-medium cursor-pointer"
                )}
                aria-current={tab.key === dealState ? "page" : undefined}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
      <div>
        {dealState === "info" && <Form company={comp} deal={deal} />}
        {dealState === "dealNotifications" && (
          <NotificationForm
            dealId={dealId}
            currency={comp.countryState?.currency || ""}
          />
        )}
        {dealState === "docs" && <DocumentsView dealId={dealId} />}
        {dealState === "notifications" && (
          <NotificationEditor dealId={dealId} />
        )}
        {dealState === "tasks" && <TaskView dealId={dealId} />}
      </div>
    </div>
  );
}
