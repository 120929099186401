import { FolderIcon, HomeIcon, UsersIcon } from "@heroicons/react/24/outline";
import { AccountViewState } from "utils/types";
import { useNavigate } from "react-router-dom";
import { classNames } from "utils/utils";
import account from "atoms/account";
import { useRecoilValue } from "recoil";
import { Roles } from "utils/enums";
import useUrlParams from "../../hooks/useUrlParams";

type NavigationType = {
  name: string;
  key: AccountViewState;
  icon: any; // TODO: Figure out type
  current: boolean;
};
const navigationAdmin: NavigationType[] = [
  { name: "Cuenta", key: "account", icon: HomeIcon, current: true },
  { name: "Usuarios", key: "users", icon: UsersIcon, current: false },
  { name: "Juicios", key: "deals", icon: FolderIcon, current: false },
];

const navigationSuperAdmin: NavigationType[] = [
  { name: "Cuenta", key: "account", icon: HomeIcon, current: true },
  { name: "Usuarios", key: "users", icon: UsersIcon, current: false },
];

const Sidebar = ({ companyId }: { companyId: string }) => {
  const navigate = useNavigate();
  const { clientState } = useUrlParams();

  const userAccount = useRecoilValue(account); // Login information
  // TODO: Change userAccountViewState to CompanyViewState

  const nav =
    userAccount.role === Roles.superadmin
      ? navigationSuperAdmin
      : navigationAdmin;

  return (
    <div className="flex flex-col bg-white w-48">
      <nav>
        <ul className="flex-col space-y-2 p-0">
          {nav.map((item) => (
            <li
              className={classNames(
                item.key === clientState
                  ? "text-indigo-600"
                  : "text-gray-600 hover:text-indigo-400",
                "flex gap-x-4 cursor-pointer"
              )}
              key={item.name}
              onClick={() => {
                navigate(
                  `/admin/?companyId=${companyId}&clientState=${item.key}`
                );
              }}
            >
              <item.icon
                className={classNames("h-6 w-6 shrink-0 ")}
                aria-hidden="true"
              />
              <div>{item.name}</div>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};
export default Sidebar;
