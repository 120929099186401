import { useEffect, useState } from "react";
import { listUsers, listUsersForCompany } from "requests/user";
import { UserType } from "utils/types";
import { useNavigate } from "react-router-dom";
import { useIsMounted } from "hooks/useIsMounted";
import userRoute from "atoms/userRoute";
import { useRecoilValue } from "recoil";
import { RoleSp } from "utils/enums";

export default function UsersTable({ companyId }: { companyId?: string }) {
  const [users, setUsers] = useState<UserType[]>([]);
  const usrRoute = useRecoilValue(userRoute);

  const navigate = useNavigate();
  const isMounted = useIsMounted();

  useEffect(() => {
    const getData = async () => {
      const dbUsers = companyId
        ? await listUsersForCompany(usrRoute.request, companyId)
        : await listUsers();
      setUsers(dbUsers);
    };
    if (isMounted()) {
      getData();
    }
  }, [companyId, isMounted]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                  >
                    Nombre
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Rol
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Cargo
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Teléfono
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Confirmado
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {users.length ? (
                  users?.map((user) => (
                    <tr key={user.id}>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        <div
                          onClick={() => {
                            navigate(
                              `/admin/?companyId=${
                                user.companyId || companyId || ""
                              }&clientState=users&userId=${user.id}`
                            );
                          }}
                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                        >
                          {user.firstName + " " + user.lastName}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {user.email}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {RoleSp[user.role]}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {user.companyRole || ""}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {user.phone || ""}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {user.confirmed ? "Sí" : "No"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <h6 className="pt-8">Tabla Vacía</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
