import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useRecoilState } from "recoil";
import alert from "atoms/alert";
import { classNames } from "utils/utils";
import { useEffect } from "react";
import { useIsMounted } from "hooks/useIsMounted";
import { defaultAlert } from "utils/constants";

export default function Alert() {
  const [alerta, setAlerta] = useRecoilState(alert);
  const isMounted = useIsMounted();

  useEffect(() => {
    const dismiss = async () => {
      setTimeout(() => {
        if (isMounted()) {
          setAlerta(defaultAlert);
        }
      }, 7000);
    };

    if (alerta.display) {
      dismiss();
    }
  }, [alerta.display, isMounted, setAlerta]);

  if (!alerta.display) return null;

  return (
    <div
      className={classNames(
        "rounded-md p-4",
        alerta.variant === "success"
          ? "bg-green-50"
          : alerta.variant === "error"
          ? "bg-red-50"
          : "bg-yellow-50",
        "animate-pulse duration-1000"
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className={classNames(
              "h-5 w-5 ",
              alerta.variant === "success"
                ? "text-green-400"
                : alerta.variant === "error"
                ? "text-red-800"
                : "text-yellow-800"
            )}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p
            className={classNames(
              "text-sm mb-0 font-medium",
              alerta.variant === "success"
                ? "text-green-800"
                : alerta.variant === "error"
                ? "text-red-400"
                : "text-yellow-400"
            )}
          >
            {alerta.message}
          </p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={classNames(
                "inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2",
                alerta.variant === "success"
                  ? "bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50"
                  : alerta.variant === "error"
                  ? "bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50"
                  : "bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50"
              )}
              onClick={() =>
                setAlerta({ display: false, variant: "success", message: "" })
              }
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
