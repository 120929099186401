import { RecoilRoot } from "recoil";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ResetPassword from "pages/ResetPassword";
import ConfirmEmail from "pages/ConfirmEmail";
import Login from "pages/Login";
import MainLayout from "components/MainLayout";
import DealsRoute from "components/shared/Deals/Route";
import AdminRoute from "components/admin/Route";
import Landing from "components/Landing";
import CompaniesRoute from "components/admin/Companies/Route";
import ClientRoute from "components/client/Route";
import UsersRoute from "components/admin/Users/Route";
import Loading from "components/Loading";
import FileDownloader from "./components/shared/FileDownloader";

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Landing>
                <Loading />
              </Landing>
            }
          ></Route>

          <Route
            path="/acreedor"
            element={
              <MainLayout>
                <ClientRoute />
              </MainLayout>
            }
          ></Route>
          <Route
            path="/admin/"
            element={
              <MainLayout>
                <AdminRoute />
              </MainLayout>
            }
          ></Route>
          <Route
            path="/admin/clientes/*"
            element={
              <MainLayout>
                <CompaniesRoute />
              </MainLayout>
            }
          ></Route>
          <Route
            path="/admin/juicios/*"
            element={
              <MainLayout>
                <DealsRoute />
              </MainLayout>
            }
          ></Route>
          <Route
            path="/admin/usuarios/*"
            element={
              <MainLayout>
                <UsersRoute />
              </MainLayout>
            }
          ></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="/confirm-user" element={<ConfirmEmail />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/document/get" element={<FileDownloader />}></Route>
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
