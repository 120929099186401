import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useIsMounted } from "hooks/useIsMounted";
import userRoute from "atoms/userRoute";
import { Tooltip } from "flowbite-react";
import { listDeals } from "requests/deal";
import {
  Procedure,
  ProcedureByTitle,
  ProceduresTitles,
  ProcedureToDealstage,
  Roles,
} from "utils/enums";
import {
  DealFilter,
  DealType,
  FilterOptionsType,
  FilterType,
  SelectedFilterType,
} from "utils/types";
import { abbrString } from "utils/utils";
import Pager from "../Pager";
import Filter from "../Filter";
import accountInState from "atoms/account";
import filterAtom from "atoms/filter";
import { listCompanies } from "requests/company";
import { listSiblingUsers } from "requests/user";

export default function DealsTable({ companyId }: { companyId?: string }) {
  const [deals, setDeals] = useState<DealType[]>([]);
  const usrRoute = useRecoilValue(userRoute);
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const userAccount = useRecoilValue(accountInState);

  const [recoilFilter, setRecoilFilter] =
    useRecoilState<FilterType>(filterAtom);

  const [nextPage, setNextPage] = useState(1);
  const [count, setCount] = useState({
    total: 0,
    countPerPage: 1,
    currentPage: 1,
  });
  const [filterOptions, setFilterOptions] = useState<FilterOptionsType[]>([]);

  const getOptionsData = useCallback(async () => {
    const usrs = await listSiblingUsers(); // Lawyers
    const cmps = companyId ? null : await listCompanies();

    // TODO: Implement nested dropdowns for this
    const dealStageProcedure = Object.values(ProceduresTitles).flatMap(
      (procedure) => {
        const procedureValue = ProcedureByTitle[procedure];
        const procedureLabel = procedure;

        return ProcedureToDealstage[Procedure[procedureValue]].map(
          (dealStage, index) => ({
            value: JSON.stringify({
              procedure: procedureValue,
              dealStage: index,
            }),
            label: `${procedureLabel} - ${dealStage}`,
            checked: false,
          })
        );
      }
    );

    const taskFilterOptions: FilterOptionsType[] = [
      {
        id: "caseManagerIds",
        name: "Abogado Responsable",
        type: "checkbox",
        options: usrs.map((u) => ({
          value: u.id,
          label: `${u.firstName} ${u.lastName}`,
          checked: false,
        })),
      },
      {
        id: "procedures",
        name: "Procedimiento - Etapa",
        type: "checkbox",
        options: dealStageProcedure,
      },
    ];
    if (cmps) {
      taskFilterOptions.push({
        id: "clientIds",
        name: "Cliente",
        type: "checkbox",
        options: cmps.map((c) => ({
          value: c.id,
          label: c.name,
          checked: false,
        })),
      });
    }
    setFilterOptions(taskFilterOptions);
  }, [companyId]);

  const getData = useCallback(async () => {
    // TODO: Figure out how to set unauthorized Alert when reponse is 403
    const dls = await listDeals({
      requestRoute: usrRoute.request,
      companyId,
      includeCourt: true,
      includeManager: true,
      page: nextPage,
      filter: recoilFilter.deal,
    });

    if (dls?.success && isMounted()) {
      setDeals(dls.data);
      setCount({
        total: dls.totalCount,
        countPerPage: dls.countPerPage,
        currentPage: dls.currentPage,
      });
    }
  }, [companyId, nextPage, usrRoute.request, recoilFilter.deal]);

  useEffect(() => {
    if (isMounted()) {
      getData();
    }
  }, [companyId, isMounted, getData]);

  useEffect(() => {
    if (isMounted()) {
      getOptionsData();
    }
  }, [getOptionsData, isMounted]);

  const onFilterChange = (filterList: SelectedFilterType[]) => {
    const filterObj = filterList.reduce((acc, curr) => {
      if (curr.type === "checkbox") {
        // @ts-ignore
        const currentValuesByCategory = acc[curr.categoryId] || [];

        const updatedValuesCategory = [...currentValuesByCategory, curr.value];

        return { ...acc, [curr.categoryId]: updatedValuesCategory };
      } else if (curr.type === "radio") {
        return { ...acc, [curr.categoryId]: curr.value };
      }
      return acc;
    }, {} as DealFilter);

    setRecoilFilter({ ...recoilFilter, deal: filterObj });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 ">
      <div className="flow-root">
        {userAccount.role >= Roles.internal ? (
          <Filter
            initialFilters={recoilFilter.deal}
            filterOptions={filterOptions}
            onFilterChange={onFilterChange}
          />
        ) : null}
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 ">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-300 mb-4">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                  >
                    Demandado
                  </th>
                  <th
                    scope="col"
                    className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                  >
                    Carátula / Juicio
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Rol
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Tribunal
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Procedimiento
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Etapa
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Abogado Responsable
                  </th>
                  {!companyId && (
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Cliente
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {deals.length ? (
                  deals.map((deal) => (
                    <tr key={`dealtable-${deal.debtor}`}>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        <div
                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                          onClick={() => {
                            const companyData =
                              usrRoute.navigation === "admin"
                                ? `companyId=${deal.company?.id || companyId}&`
                                : "";
                            const isAdmin = usrRoute.navigation === "admin";
                            navigate(
                              `/${usrRoute.navigation}?${companyData}dealId=${
                                deal.id
                              }${isAdmin ? `&clientState=deals` : ""}`
                            );
                          }}
                        >
                          <Tooltip
                            hidden={deal.debtor.length <= 24}
                            content={deal.debtor}
                          >
                            {abbrString(deal.debtor, 24)}
                          </Tooltip>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {deal.dealname ? (
                          <Tooltip
                            hidden={deal.dealname.length <= 24}
                            content={deal.dealname}
                          >
                            {abbrString(deal.dealname, 24)}
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {deal.caseId || "-"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {deal.court?.name || "-"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {ProceduresTitles[Procedure[deal.procedure]]}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {
                          ProcedureToDealstage[Procedure[deal.procedure]][
                            deal.dealstage
                          ]
                        }
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {`${deal.caseManager?.firstName} ${deal.caseManager?.lastName}`}
                      </td>
                      {!companyId && (
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                          {deal.company?.name}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <h6 className="pt-8">Tabla Vacía</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pager
              totalPages={Math.ceil(count.total / count.countPerPage)}
              current={count.currentPage}
              onChangePage={setNextPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
