import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import alert from "atoms/alert";
import confirmModal from "atoms/confirmModal";
import userRoute from "atoms/userRoute";
import { createOrUpdateDeal, deleteDeal } from "requests/deal";
import { listSiblingUsers, listUsersForCompany } from "requests/user";
import {
  ProceduresTitles,
  Procedure,
  ProcedureToDealstage,
  LegalEntityType,
} from "utils/enums";
import {
  CompanyType,
  CourtType,
  DealFormType,
  DealType,
  UserType,
} from "utils/types";
import { classNames, iterableEnum, validateEmail } from "utils/utils";
import { useIsMounted } from "hooks/useIsMounted";
import { listCourtsForCountryStateId } from "requests/court";
import DropdownSearch from "components/shared/DropdownSearch";

const initialState: DealFormType = {
  dealname: "",
  companyId: "",
  ownerId: "",
  caseManagerId: "",
  debtorEntityType: LegalEntityType.natural,
  caseId: "",
  procedure: 0,
  dealstage: 0,
  debtor: "",
  debtorEmail: "",
  debtorTaxId: "",
  debtorContact: "",
  debtorAddress: "",
  inDefault: false,
  totalDebt: 0,
  totalDebtLarge: "",
  courtId: "",
  comission: 0,
  externalUrl: "",
};
const requiredFields = ["debtor"];
const DealForm = ({
  company,
  deal,
}: {
  company: CompanyType;
  deal?: DealType;
}) => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();

  const [users, setUsers] = useState<UserType[]>([]);
  const [lawyers, setLawyers] = useState<UserType[]>([]);

  const [courts, setCourts] = useState<CourtType[]>([]);

  const setAlerta = useSetRecoilState(alert);
  const usrRoute = useRecoilValue(userRoute);
  const setConfirmModal = useSetRecoilState(confirmModal);

  const [payload, setPayload] = useState({
    ...initialState,
    companyId: company.id,
  });

  const countryState = company.countryState;

  useEffect(() => {
    if (deal && isMounted()) {
      setPayload({
        dealname: deal?.dealname || "",
        caseId: deal?.caseId || "",
        procedure: deal?.procedure || 0,
        dealstage: deal?.dealstage || 0,
        debtor: deal?.debtor || "",
        debtorContact: deal?.debtorContact || "",
        debtorEmail: deal?.debtorEmail || "",
        debtorTaxId: deal?.debtorTaxId,
        debtorAddress: deal?.debtorAddress || "",
        debtorEntityType: deal?.debtorEntityType || LegalEntityType.natural,
        // totalDebt: deal?.totalDebt || 0,
        totalDebtLarge: deal?.totalDebtLarge || "",
        externalUrl: deal?.externalUrl,
        inDefault: deal?.inDefault || false,
        ownerId: deal?.owner?.id || "",
        caseManagerId: deal?.caseManager?.id || "",
        comission: deal?.comission || 0,
        companyId: deal?.company?.id || "",
        courtId: deal?.court?.id || "",
      });
    }
  }, [deal, isMounted, usrRoute]);

  useEffect(() => {
    const getCourts = async (countryStateId: string) => {
      const crts = await listCourtsForCountryStateId({ countryStateId });
      if (crts) {
        setCourts(crts);
      }
    };
    if (countryState?.id) {
      getCourts(countryState?.id);
    }
  }, [countryState?.id]);

  const getCompanyUsers = useCallback(async () => {
    if (payload.companyId) {
      const usrs = await listUsersForCompany(
        usrRoute.request,
        payload.companyId
      );
      setUsers(usrs);
    }
  }, [payload.companyId, usrRoute.request]);

  const getManagerUsers = useCallback(async () => {
    const lws = await listSiblingUsers();
    setLawyers(lws);
  }, []);

  useEffect(() => {
    if (isMounted()) {
      getCompanyUsers();
    }
  }, [getCompanyUsers, isMounted]);

  useEffect(() => {
    if (isMounted()) {
      getManagerUsers();
    }
  }, [getManagerUsers, isMounted]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const response = await createOrUpdateDeal({
      ...payload,
      id: deal?.id,
    });

    if (response?.success) {
      setAlerta({
        display: true,
        variant: "success",
        message: "Juicio creado o actualizado con éxito",
      });
      navigate(-1);
    } else {
      setAlerta({
        display: false,
        variant: "error",
        message: "Hubo un error creando / actualizando el juicio",
      });
    }
  };

  const handleFieldChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const value = e.currentTarget.value;
      let v =
        value === "" || Number.isNaN(Number(e.currentTarget.value))
          ? e.currentTarget.value
          : Number(e.currentTarget.value);

      if (key === "totalDebtLarge") {
        v = v.toString();
      }
      const newPayload = {
        ...payload,
        [key]: v,
      };
      setPayload(newPayload);
    };

  const handleCheckboxChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newPayload = {
        ...payload,
        [key]: !payload.inDefault,
      };
      setPayload(newPayload);
    };

  // TODO: Figure out button disable view
  const formValid = useMemo(() => {
    const emptyFields =
      Object.entries(payload)
        .filter(([k, _]) => requiredFields.includes(k))
        .filter(([k, v]) => !v).length > 0;

    // const validEmail =
    //   Boolean(payload.debtorEmail) &&
    //   payload.debtorEmail &&
    //   validateEmail(payload.debtorEmail);

    return !emptyFields;
  }, [payload]);

  const handleSelectChange =
    (key: string) => (e: React.ChangeEvent<HTMLSelectElement>) => {
      const v = Number.isNaN(Number(e.currentTarget.value))
        ? e.currentTarget.value
        : Number(e.currentTarget.value);

      e.preventDefault();
      const newPayload = {
        ...payload,
        [key]: v,
      };

      // reset dropdowns
      if (key === "procedure") {
        newPayload["dealstage"] = 0;
      }

      setPayload(newPayload);
    };

  const handleFilterSelect = (key: string) => (value: string) => {
    const v = Number.isNaN(Number(value)) ? value : Number(value);

    // e.preventDefault();
    const newPayload = {
      ...payload,
      [key]: v,
    };

    // reset dropdowns
    if (key === "procedure") {
      newPayload["dealstage"] = 0;
    }

    setPayload(newPayload);
  };

  const setDeleteModal = (debtor: string) => {
    setConfirmModal({
      display: true,
      onConfirm: async () => {
        await handleDelete();
      },
      title: "Borrar juicio",
      variant: "alert",
      message: `¿Estas seguro(a) de querer borrar el juicio a ${debtor} ?`,
    });
  };

  const handleDelete = async () => {
    if (deal?.id) {
      const deleteSuccess = await deleteDeal(deal.id);
      if (deleteSuccess) {
        setAlerta({
          display: true,
          variant: "success",
          message: "Juicio borrado con éxito",
        });
        navigate(-1);
      } else {
        setAlerta({
          display: false,
          variant: "error",
          message: "Hubo un error al borrar el juicio",
        });
      }
    }
  };

  const dealstageTitles = useMemo(
    () =>
      payload.procedure !== undefined
        ? ProcedureToDealstage[Procedure[payload.procedure]]
        : [],
    [payload.procedure]
  );
  const inDefault = useMemo(() => payload.inDefault, [payload.inDefault]);
  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-12 mt-4">
        <h2>Información General</h2>
        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 gap-y-10">
          <div className="sm:col-span-2">
            <label
              htmlFor="debtor-entity-type"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Persona
            </label>
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <select
                  onChange={handleSelectChange("debtorEntityType")}
                  name="debtor-entity-type"
                  id="debtor-entity-type"
                  value={payload.debtorEntityType}
                  className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value={LegalEntityType.natural}>
                    Persona Natural
                  </option>
                  <option value={LegalEntityType.corporate}>
                    Persona Jurídica
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="debtor"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Empresa Contraparte *
            </label>
            <div className="mt-2">
              <input
                onChange={handleFieldChange("debtor")}
                type="text"
                name="debtor"
                id="debtor"
                value={payload.debtor}
                autoComplete="debtor"
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="debtor-contact"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Contacto Contraparte
            </label>
            <div className="mt-2">
              <input
                onChange={handleFieldChange("debtorContact")}
                type="text"
                name="debtor-contact"
                id="debtor-contact"
                value={payload.debtorContact}
                autoComplete="debtor-contact"
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="debtor-email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email Deudor
            </label>
            <div className="mt-2">
              <input
                onChange={handleFieldChange("debtorEmail")}
                type="text"
                name="debtor-email"
                id="debtor-email"
                value={payload.debtorEmail}
                placeholder="deudor@email.com"
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="debtor-tax-id"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Rut Contraparte
            </label>
            <div className="mt-2">
              <input
                onChange={handleFieldChange("debtorTaxId")}
                type="text"
                name="debtor-tax-id"
                id="debtor-tax-id"
                value={payload.debtorTaxId}
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="debtor-address"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Dirección Contraparte
            </label>
            <div className="mt-2">
              <input
                onChange={handleFieldChange("debtorAddress")}
                type="text"
                name="debtor-address"
                id="debtor-address"
                value={payload.debtorAddress}
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="external-url"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              URL
            </label>
            <div className="mt-2">
              <input
                onChange={handleFieldChange("externalUrl")}
                type="text"
                name="external-url"
                id="external-url"
                value={payload.externalUrl}
                placeholder="www.example.com"
                autoComplete="external-url"
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="external-url"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Comisión de recupero (%)
            </label>
            <div className="mt-2">
              <input
                onChange={handleFieldChange("comission")}
                type="number"
                min={0}
                max={100}
                name="comision"
                id="comision"
                value={Math.min(payload.comission || 0, 100)}
                placeholder="0-100 %"
                autoComplete="comision"
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
        <h2>Información Juicio</h2>
        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 gap-y-10">
          <div className="sm:col-span-3">
            <label
              htmlFor="procedure"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Procedimiento
            </label>
            <div className="mt-2">
              <select
                onChange={handleSelectChange("procedure")}
                id="procedure"
                name="procedure"
                value={payload.procedure}
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                {iterableEnum(Procedure).map((label, idx) => (
                  <option key={`procedure-${label}`} value={idx}>
                    {ProceduresTitles[label]}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="dealstage"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Estado juicio
            </label>
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <select
                  onChange={handleSelectChange("dealstage")}
                  name="dealstage"
                  id="dealstage"
                  value={payload.dealstage}
                  className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  {dealstageTitles.map((dst, idx) => (
                    <option key={`dealtstage-${dst}`} value={idx}>
                      {dst}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="dealname"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Carátula
            </label>
            <div className="mt-2">
              <input
                onChange={handleFieldChange("dealname")}
                type="text"
                name="dealname"
                id="dealname"
                value={payload.dealname}
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="case-id"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Rol
            </label>
            <div className="mt-2">
              <input
                onChange={handleFieldChange("caseId")}
                type="text"
                name="case-id"
                id="case-id"
                value={payload.caseId}
                autoComplete="family-name"
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="dealstage"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Tribunal
            </label>
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <DropdownSearch
                  selected={payload.courtId}
                  options={courts.map((c) => ({ key: c.id, value: c.name }))}
                  onSelect={handleFilterSelect("courtId")}
                />
              </div>
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="total-debt"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Monto demandado
            </label>
            <div className="mt-2">
              <input
                onChange={handleFieldChange("totalDebtLarge")}
                type="number"
                name="total-debt"
                id="total-debt"
                value={payload.totalDebtLarge}
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-1">
            <label
              htmlFor="in-default"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              En Rebeldía
            </label>
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <fieldset>
                  <input
                    onChange={handleCheckboxChange("inDefault")}
                    name="in-default"
                    id="in-default"
                    type="checkbox"
                    checked={inDefault}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </fieldset>
              </div>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="owner"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Usuario Responsable
            </label>
            <div className="mt-2">
              <select
                onChange={handleSelectChange("ownerId")}
                id="owner"
                name="owner"
                value={payload.ownerId}
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                {!payload.ownerId ? (
                  <option value="">- No asignado -</option>
                ) : null}

                {users.map((u) => (
                  <option key={`${u.id}`} value={u.id}>
                    {`${u.firstName} ${u.lastName}`}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="caseManager"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Abogado Responsable
            </label>
            <div className="mt-2">
              <select
                onChange={handleSelectChange("caseManagerId")}
                id="caseManager"
                name="caseManager"
                value={payload.caseManagerId}
                className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                {!payload.caseManagerId ? (
                  <option value="">- No asignado -</option>
                ) : null}

                {lawyers.map((cm) => (
                  <option key={`${cm.id}`} value={cm.id}>
                    {`${cm.firstName} ${cm.lastName}`}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 flex items-center justify-between">
        {deal?.id && payload?.debtor && (
          <button
            type="button"
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            onClick={() => setDeleteModal(payload.debtor || "")}
          >
            Borrar
          </button>
        )}
        <div className="flex gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => {
              navigate(-1);
            }}
          >
            Volver
          </button>
          <button
            type="submit"
            disabled={!formValid}
            className={
              formValid
                ? classNames(
                    "rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  )
                : classNames(
                    "rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  )
            }
          >
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default DealForm;
