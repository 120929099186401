import { CourtType } from "utils/types";
import { ResponseStatus } from "utils/enums";
import { post } from "./utils";

export type CourtListResponse = {
  success: boolean;
  data: CourtType[];
  status: ResponseStatus;
};

export type CourtGetResponse = {
  success: boolean;
  data: CourtType;
  status: ResponseStatus;
};

export const listCourtsForCountryStateId = async ({
  countryStateId,
}: {
  countryStateId: string;
}) => {
  const deals = await post<CourtListResponse>({
    route: "court/listForCountryStateId",
    payload: {
      countryStateId,
    },
  });
  return deals?.data || [];
};

// Unused
export const listCourtsForDealId = async (dealId: string) => {
  const res = await post<CourtListResponse>({
    route: "court/listForDealId",
    payload: {
      dealId,
    },
  });

  return res?.data;
};

export const getCourt = async (courtId: string) => {
  const res = await post<CourtGetResponse>({
    route: "court/get",
    payload: {
      courtId,
    },
  });

  return res?.data;
};
