import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
// import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useRecoilState } from "recoil";
import accountInState from "atoms/account";
import { ModeType } from "utils/types";
import { logout } from "requests/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { Roles } from "utils/enums";
import { classNames } from "utils/utils";
import logo from "assets/lawgico-logo-w.png";
import { UserCircleIcon } from "@heroicons/react/20/solid";
import { getOwnCompany } from "requests/company";
import { Tooltip } from "flowbite-react";

export default function AdminNavbar() {
  const [userAccount, setUserAccount] = useRecoilState(accountInState);
  const [companyName, setCompanyName] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = userAccount.role === Roles.admin;
  const navbarModes: ModeType[] = isAdmin
    ? ["Tareas", "Clientes", "Juicios"]
    : ["Clientes"];

  // TODO: Put selected company name on title
  const [recoilMode] = location.pathname.split("/").slice(1);

  const fetchCompany = async () => {
    const c = await getOwnCompany();
    if (c) {
      setCompanyName(c.name);
    }
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  const handleLogout = useCallback(async () => {
    await logout();

    setUserAccount({
      userId: "",
      email: "",
      role: 0,
      companyId: "",
    });

    navigate("/login");
  }, [navigate, setUserAccount]);

  const prod = process.env.NODE_ENV === "production";

  const userNavigation = useMemo(
    () => [
      // { name: "Your Profile", callback: () => {} },
      // { name: "Settings", callback: () => {} },
      { name: "Sign out", callback: handleLogout },
    ],
    [handleLogout]
  );

  return (
    <>
      <div className="min-h-full">
        <div
          className={
            isAdmin
              ? classNames("bg-primary-blue pb-32")
              : classNames("bg-purple-600 pb-32")
          }
        >
          <Disclosure
            as="nav"
            className={
              isAdmin
                ? classNames(
                    "border-b border-indigo-300 border-opacity-25 primary-blue lg:border-none"
                  )
                : classNames(
                    "border-b border-purple-300 border-opacity-25 bg-purple-600 lg:border-none"
                  )
            }
          >
            {({ open }) => (
              <>
                <div className="mx-auto max-w-8xl px-2 sm:px-4 lg:px-8">
                  <div
                    className={
                      isAdmin
                        ? classNames(
                            "relative flex h-16 items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25"
                          )
                        : classNames(
                            "relative flex h-16 items-center justify-between lg:border-b lg:border-purple-400 lg:border-opacity-25"
                          )
                    }
                  >
                    <div className="flex items-center px-2 lg:px-0">
                      <div className="flex-shrink-0">
                        <Tooltip
                          hidden={!process.env.REACT_APP_VERSION}
                          content={`Versión: ${process.env.REACT_APP_VERSION}`}
                        >
                          <img
                            className="block h-8 w-12"
                            src={logo}
                            alt="Lawgico"
                          />
                        </Tooltip>
                      </div>
                      <div className="hidden lg:ml-10 lg:block">
                        <div className="flex space-x-4">
                          {navbarModes.map((k) => (
                            <div
                              key={k}
                              onClick={() => {
                                k === "Tareas"
                                  ? navigate("/admin")
                                  : navigate(`/admin/${k.toLowerCase()}`);
                              }}
                              className={classNames(
                                recoilMode === k.toLowerCase()
                                  ? isAdmin
                                    ? "bg-indigo-700 text-white"
                                    : "bg-purple-700 text-white"
                                  : isAdmin
                                  ? "text-white hover:bg-indigo-500 hover:bg-opacity-75"
                                  : "text-white hover:bg-purple-500 hover:bg-opacity-75",
                                "rounded-md py-2 px-3 text-sm font-medium cursor-pointer"
                              )}
                              aria-current={
                                recoilMode === k.toLowerCase()
                                  ? "page"
                                  : undefined
                              }
                            >
                              {k}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="flex lg:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                        <span className="absolute -inset-0.5" />
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className="hidden lg:ml-4 lg:block">
                      <div className="flex items-center">
                        {/* <button
                          type="button"
                          className="relative flex-shrink-0 rounded-full bg-indigo-600 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                        >
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">View notifications</span>
                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </button> */}

                        {/* Profile dropdown */}
                        <Menu as="div" className="relative ml-3 flex-shrink-0">
                          <div className="flex text-xs text-white gap-x-2 items-center">
                            <Menu.Button className="relative flex rounded-full bg-indigo-600 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                              <span className="absolute -inset-1.5" />
                              <span className="sr-only">Open user menu</span>
                              <UserCircleIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                              {/* <img
                                className="h-8 w-8 rounded-full"
                                src={placeholderImage}
                                alt="DM"
                              /> */}
                            </Menu.Button>
                            <div>
                              {userAccount.email} - {companyName || ""}{" "}
                              {!prod ? "(Dev)" : ""}
                            </div>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {userNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <div
                                      onClick={item.callback}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                      )}
                                    >
                                      {item.name}
                                    </div>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="lg:hidden">
                  <div className="space-y-1 px-2 pb-3 pt-2">
                    {navbarModes.map((k) => (
                      <Disclosure.Button
                        key={k}
                        onClick={() => {
                          k === "Tareas"
                            ? navigate("/")
                            : navigate(`/${k.toLowerCase()}`);
                        }}
                        className={classNames(
                          recoilMode === k.toLowerCase()
                            ? "bg-indigo-700 text-white"
                            : "text-white hover:bg-indigo-500 hover:bg-opacity-75",
                          "block rounded-md py-2 px-3 text-base font-medium"
                        )}
                        aria-current={
                          recoilMode === k.toLowerCase() ? "page" : undefined
                        }
                      >
                        {k}
                      </Disclosure.Button>
                    ))}
                  </div>
                  <div className="border-t border-indigo-700 pb-3 pt-4">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <UserCircleIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                        {/* <img
                          className="h-10 w-10 rounded-full"
                          src={placeholderImage}
                          alt="DM"
                        /> */}
                      </div>
                      <div className="ml-3">
                        {/* <div className="text-base font-medium text-white">
                          {userAccount.name}
                        </div> */}
                        <div className="text-sm font-medium text-indigo-300">
                          {userAccount.email}
                        </div>
                      </div>
                      <button
                        type="button"
                        className="relative ml-auto flex-shrink-0 rounded-full bg-indigo-600 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-3 space-y-1 px-2">
                      {userNavigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          onClick={item.callback}
                          className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75"
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </>
  );
}
