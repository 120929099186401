import { useEffect, useMemo, useState } from "react";
import { CompanyFormType, CountryStateType } from "utils/types";
import { createCompany, getCompany, deleteCompany } from "requests/company";
import { useNavigate } from "react-router-dom";
import { classNames } from "utils/utils";
import alert from "atoms/alert";
import confirmModal from "atoms/confirmModal";
import { useSetRecoilState } from "recoil";
import { useIsMounted } from "hooks/useIsMounted";
import { listCountryStates } from "requests/countryState";

const initialState: CompanyFormType = {
  name: "",
  taxId: "",
  website: "",
  countryStateId: "",
  city: "",
  stateOrProvince: "",
  streetAddress: "",
  postalCode: "",
};
const CompanyForm = ({ companyId }: { companyId?: string }) => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const setAlerta = useSetRecoilState(alert);
  const setConfirmModal = useSetRecoilState(confirmModal);
  const [countries, setCountries] = useState<CountryStateType[]>([]);

  const [payload, setPayload] = useState(initialState);

  const getCompanyData = async (comId: string) => {
    const c = await getCompany(comId);

    setPayload({
      name: c?.name || "",
      taxId: c?.taxId || "",
      website: c?.website || "",
      city: c?.city || "",
      stateOrProvince: c?.stateOrProvince || "",
      streetAddress: c?.streetAddress || "",
      postalCode: c?.postalCode || "",
      countryStateId: c?.countryState?.id || "",
    });
  };

  const getCountries = async () => {
    const cs = await listCountryStates();
    setCountries(cs);
  };

  useEffect(() => {
    if (isMounted()) {
      if (companyId) {
        getCompanyData(companyId);
      }
      getCountries();
    }
  }, [companyId, isMounted]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const c = await createCompany({ ...payload, id: companyId });
    if (c) {
      navigate(-1);
      setAlerta({
        display: true,
        variant: "success",
        message: "Compañía creada o actualizada con éxito",
      });
    } else {
      setAlerta({
        display: false,
        variant: "error",
        message: "Hubo un error creando / actualizando la compañía",
      });
    }
  };

  const setDeleteModal = (companyName: string) => {
    setConfirmModal({
      display: true,
      onConfirm: async () => {
        await handleDelete();
      },
      title: "Borrar compañía",
      variant: "alert",
      message: `¿Estas seguro(a) de querer borrar la compañía ${companyName} ?`,
    });
  };

  const handleDelete = async () => {
    if (companyId) {
      const deleteSuccess = await deleteCompany(companyId);
      if (deleteSuccess) {
        setAlerta({
          display: true,
          variant: "success",
          message: "Compañía borrada con éxito",
        });
        navigate("/admin/clientes"); // If deleting company we go to company list
      } else {
        setAlerta({
          display: false,
          variant: "error",
          message: "Hubo un error al borrar la compañía",
        });
      }
    }
  };

  const handleFieldChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      const newPayload = {
        ...payload,
        [key]: e.currentTarget.value,
      };
      setPayload(newPayload);
    };

  const formValid = useMemo(() => {
    return Boolean(payload.name && payload.taxId);
  }, [payload]);

  const handleSelectChange =
    (key: string) => (e: React.ChangeEvent<HTMLSelectElement>) => {
      e.preventDefault();
      const newPayload = {
        ...payload,
        [key]: e.currentTarget.value,
      };
      setPayload(newPayload);
    };

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-3">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nombre empresa
              </label>
              <div className="mt-2">
                <input
                  onChange={handleFieldChange("name")}
                  type="text"
                  name="name"
                  id="name"
                  value={payload.name}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="tax-id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                RUT
              </label>
              <div className="mt-2">
                <input
                  onChange={handleFieldChange("taxId")}
                  type="text"
                  name="tax-id"
                  id="tax-id"
                  value={payload.taxId}
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="max-w-2xl gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-3">
              <div className="">
                <label
                  htmlFor="website"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Sitio Web
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                      http://
                    </span>
                    <input
                      onChange={handleFieldChange("website")}
                      type="text"
                      name="website"
                      id="website"
                      value={payload.website}
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="www.example.com"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="country"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                País
              </label>
              <div className="mt-2">
                <select
                  onChange={handleSelectChange("countryStateId")}
                  id="country-state"
                  name="country-state"
                  value={payload.countryStateId}
                  autoComplete="country-state"
                  className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value="">-- Selecciona País --</option>
                  {countries.map((c) => (
                    <option key={`country-${c.id}`} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-span-full">
              <label
                htmlFor="street-address"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Dirección
              </label>
              <div className="mt-2">
                <input
                  onChange={handleFieldChange("streetAddress")}
                  type="text"
                  name="street-address"
                  id="street-address"
                  value={payload.streetAddress}
                  autoComplete="street-address"
                  className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2 sm:col-start-1">
              <label
                htmlFor="city"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Ciudad
              </label>
              <div className="mt-2">
                <input
                  onChange={handleFieldChange("city")}
                  type="text"
                  name="city"
                  id="city"
                  value={payload.city}
                  autoComplete="address-level2"
                  className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="region"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Estado / Provincia / Región
              </label>
              <div className="mt-2">
                <input
                  onChange={handleFieldChange("stateOrProvince")}
                  type="text"
                  name="region"
                  id="region"
                  value={payload.stateOrProvince}
                  autoComplete="address-level1"
                  className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="postal-code"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Código Postal
              </label>
              <div className="mt-2">
                <input
                  onChange={handleFieldChange("postalCode")}
                  type="text"
                  name="postal-code"
                  id="postal-code"
                  value={payload.postalCode}
                  autoComplete="postal-code"
                  className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-between">
        {companyId && (
          <button
            type="button"
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            onClick={() => setDeleteModal(payload.name)}
          >
            Borrar
          </button>
        )}
        <div className="flex gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => {
              navigate(-1);
            }}
          >
            Atrás
          </button>
          <button
            type="submit"
            disabled={!formValid}
            className={
              formValid
                ? classNames(
                    "rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  )
                : classNames(
                    "rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  )
            }
          >
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default CompanyForm;
