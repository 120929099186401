import { useMemo, useEffect, useRef, useState } from "react";
import { OptionType } from "utils/types";
import SearchIcon from "./icons/SearchIcon";
import CaretDownIcon from "./icons/CaretDownIcon";

const DropdownSearch = ({
  options,
  onSelect,
  selected,
}: {
  options: OptionType[];
  onSelect: (e: string) => void;
  selected?: string;
}) => {
  const [filter, setFilter] = useState("");
  const [show, setShow] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current?.contains(event.target as Node)
      ) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredOptions = useMemo(
    () =>
      filter.length >= 3
        ? options.filter(
            (option) =>
              option.value.toLowerCase().indexOf(filter.toLowerCase()) >= 0
          )
        : options,
    [options, filter]
  );

  const selectedLabel = useMemo(() => {
    const selectedOption = options.find((option) => option.key === selected);
    return selectedOption ? selectedOption.value : "";
  }, [options, selected]);

  return (
    <div className="w-full" ref={dropdownRef}>
      <div
        onClick={() => setShow(!show)}
        className="flex h-9 hover:bg-gray-100 block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm"
      >
        <div className="w-full">{selectedLabel}</div>
        <div
          id="dropdownSearchdiv"
          data-dropdown-toggle="dropdownSearch"
          data-dropdown-placement="bottom"
          className="font-medium text-sm pl-1 pr-3 py-2.5"
        >
          <CaretDownIcon />
        </div>
      </div>

      {show && (
        <div
          id="dropdownSearch"
          className="relative z-10 rounded-lg shadow dark:bg-gray-700"
        >
          <div className="w-full absolute inset-y-0 rtl:inset-r-0 start-0">
            <div className="p-3 flex flex-row bg-white">
              <label htmlFor="input-group-search" className="sr-only">
                Buscar
              </label>

              <input
                type="text"
                id="input-group-search"
                className="w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Búsqueda"
                value={filter}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setFilter(e.target.value);
                }}
                onKeyPress={(e) => {
                  // NOTE: Prevent form submission on Enter
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              />
              <div className="flex items-center ps-3 pointer-events-none">
                <SearchIcon />
              </div>
            </div>

            <ul className="max-h-40 overflow-y-auto flex flex-col ps-2 rounded bg-white">
              {filteredOptions.map((option) => (
                <li
                  key={`option-${option.key}`}
                  className="flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 text-xs"
                >
                  <div
                    onClick={() => {
                      onSelect(option.key);
                      setShow(false);
                      setFilter("");
                    }}
                  >
                    {option.value}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownSearch;
