import { Routes, Route } from "react-router-dom";
import Header from "./Header";
import Table from "./Table";
import Form from "./Form";

const Companies = () => {
  return (
    <main className="-mt-32">
      <div className="mx-auto max-w-8xl px-4 pb-12 sm:px-6 lg:px-8">
        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          <Header />
          <Routes>
            <Route path="/" element={<Table />} />
            <Route path="/add" element={<Form />} />
          </Routes>
        </div>
      </div>
    </main>
  );
};

export default Companies;
