import {
  DealFilter,
  FilterOptionsType,
  SelectedFilterType,
  TaskFilter,
} from "./types";

export const filterCountBySection = (
  filterOptions: FilterOptionsType[],
  localFilters: SelectedFilterType[]
) =>
  filterOptions.reduce((acc, section) => {
    const id = section.id;
    const count = localFilters.filter((lf) => lf.categoryId === id).length;

    return { ...acc, [id]: count };
  }, {} as { [key: string]: number });

export const filterTypeToSelectedFilterType = (
  taskType: TaskFilter | DealFilter, // TODO: Add other Filter types
  filterOptions: FilterOptionsType[]
) => {
  const selectedFilter: SelectedFilterType[] = [];

  filterOptions.forEach((filterOption) => {
    const { id, type, options } = filterOption; // Filter options
    // @ts-ignore
    const filtersValueOrValues = taskType[id]; // Selected values for the filter of a specific category

    if (filtersValueOrValues === undefined) return;

    if (type === "checkbox") {
      for (const fv of filtersValueOrValues) {
        const selectedOption = options.find((o) => o.value === fv);
        selectedFilter.push({
          categoryId: id,
          value: fv, // multiple filter values
          label: selectedOption?.label || "",
          type: type,
        });
      }
    } else if (type === "radio") {
      const selectedOption = options.find(
        (o) => o.value === filtersValueOrValues
      );
      selectedFilter.push({
        categoryId: id,
        value: filtersValueOrValues, // individual filter value
        label: selectedOption?.label || "",
        type: type,
      });
    }
  });

  return selectedFilter;
};
