"use client";
import { useState, useCallback, useEffect } from "react";
import { TaskParsedType } from "utils/types";
import { listTasksForDeal } from "requests/task";
import TaskList from "./List";
import TaskForm from "./Form";

const TasksView = ({ dealId }: { dealId: string }) => {
  // TODO: Add validation and clean up

  const [tasks, setTasks] = useState<TaskParsedType[]>([]);
  const [nextPage, setNextPage] = useState(1);
  const [refresh, setRefresh] = useState(true);
  const [editingTask, setEditingTask] = useState<TaskParsedType | undefined>(
    undefined
  );

  const [more, setMore] = useState(true);

  const [messagesEmpty, setMessagesEmpty] = useState(true);

  const listTaks = useCallback(async () => {
    const res = await listTasksForDeal({
      dealId,
      page: nextPage,
      includeAssignedUser: true,
    });

    const parsedTasks = res?.data.map((n) => ({
      ...n,
      content: JSON.parse(n.content),
    })) as TaskParsedType[];

    if (
      (res?.countPerPage || 1) * (res?.currentPage || 1) >=
      (res?.totalCount || 0)
    ) {
      setMore(false);
    }
    setMessagesEmpty(!Boolean(res?.totalCount));
    setTasks([...tasks, ...parsedTasks]);
    setNextPage(nextPage + 1);
    setRefresh(false);
  }, [dealId, nextPage, tasks]);

  const onEdit = useCallback(
    (id: string) => {
      const task = tasks.find((t) => t.id === id);
      if (task) {
        setEditingTask(task);
      }
    },
    [tasks]
  );

  useEffect(() => {
    if (refresh) {
      listTaks();
    }
  }, [listTaks, refresh]);

  const onSaveCallback = () => {
    setNextPage(1);
    setMore(true);
    setTasks([]);
    setRefresh(true);
    setEditingTask(undefined);
  };

  return (
    <div className="flex mt-4 gap-x-8">
      <TaskForm
        dealId={dealId}
        onSaveCallback={onSaveCallback}
        task={editingTask}
        onCancelEdit={() => setEditingTask(undefined)}
      />
      <TaskList
        tasks={tasks}
        getMore={more ? () => setRefresh(true) : undefined}
        empty={messagesEmpty}
        onEdit={onEdit}
        editingTask={Boolean(editingTask)}
      />
    </div>
  );
};

export default TasksView;
