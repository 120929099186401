import Form from "./Form";
import Header from "./Header";
import Table from "./Table";

export default function UsersView({
  companyId,
  userId,
}: {
  companyId: string;
  userId?: string;
}) {
  if (userId) {
    return userId === "new" ? (
      <Form companyId={companyId} />
    ) : (
      <Form companyId={companyId} userId={userId} />
    );
  }

  return (
    <>
      <Header companyId={companyId} />
      <Table companyId={companyId} />
    </>
  );
}
